import Gltf from "nanogl-gltf/lib/Gltf";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";

export type PlatformRenderable = {
  plateformType: PlatformType;
  renderer: MeshRenderer;
};

export type PlatformType =
  | "platformStatic"
  | "platformSmall"
  | "platformOnelife";

const platformRenderablesMap = new Map<PlatformType, PlatformRenderable>();
platformRenderablesMap.set("platformStatic", {
  plateformType: "platformStatic",
  renderer: null,
});
platformRenderablesMap.set("platformSmall", {
  plateformType: "platformSmall",
  renderer: null,
});
platformRenderablesMap.set("platformOnelife", {
  plateformType: "platformOnelife",
  renderer: null,
});

export function setPlatformRenderer(gltf: Gltf) {
  platformRenderablesMap.forEach(
    (platformRenderable) =>
      (platformRenderable.renderer = gltf.getNode(
        platformRenderable.plateformType
      ).renderable)
  );
}

export default platformRenderablesMap;
