import { vec3 } from "gl-matrix";
import Platform from "./Platform";
import Camera from "nanogl-camera";
import {
  PlatformRenderable,
  PlatformType,
} from "@/services/models/PlatformModel";
import { PlatformReflectionRenderable, PlatformReflectionType } from "@/services/models/PlatformReflectionModel";
import { RenderContext } from "@webgl/core/Renderer";
import { getLevel } from "@/services/models/DifficultyLevelModel";

export default class PlatformManager {
  platforms: Map<PlatformType, Platform[]>;
  inUsePlatforms: Platform[] = [];
  latestPlatform: Platform;
  latestNonOneLifePlatform: Platform;

  progress: number;

  y = 0;
  constructor(
    private platformRenderablesMap: Map<PlatformType, PlatformRenderable>,
    public platformReflectionRenderableMap: Map<PlatformReflectionType, PlatformReflectionRenderable>
  ) { }

  start() {
    this.platforms = new Map([
      ["platformStatic",
        Array.from({ length: 50 }).map(() =>
          new Platform(this.platformRenderablesMap.get("platformStatic"), this.platformReflectionRenderableMap, "platformStatic"))],
      ["platformSmall",
        Array.from({ length: 50 }).map(() =>
          new Platform(this.platformRenderablesMap.get("platformSmall"), this.platformReflectionRenderableMap, "platformSmall"))],
      ["platformOnelife",
        Array.from({ length: 50 }).map(() =>
          new Platform(this.platformRenderablesMap.get("platformOnelife"), this.platformReflectionRenderableMap, "platformOnelife"))],
    ]);
    // this.plafformReflections = [];
    this.progress = 0;

    const fPlatform = this.addPlatform("platformStatic", vec3.fromValues(0, 2.25, 0));
    this.y = 2.25;
    fPlatform.setY(this.y);
    this.y += 0.25;
    for (let i = 0; i < 5; i++) {
      this.addPlatform();
    }
  }

  stop() {
    this.platforms.forEach((platforms) => platforms.forEach((platform) => platform.dispose()));
    // this.plafformReflections.forEach((platform) => platform.dispose());
  }

  getFreePlatforms(type: PlatformType) {
    return this.platforms.get(type)?.filter((platform) => !platform.getInUse());
  }

  addPlatform(type?: PlatformType, position?: vec3): Platform {
    let t = type;
    if (!type) {
      const difficultyLevelData = getLevel(this.progress);
      const rand = Math.random();
      if (rand < difficultyLevelData.platformStaticFactor) {
        t = "platformStatic"
      } else if (
        rand > difficultyLevelData.platformStaticFactor &&
        rand <
        difficultyLevelData.platformStaticFactor +
        difficultyLevelData.platformMovingFactor
      ) {
        t = "platformSmall"
      } else if (
        rand >
        difficultyLevelData.platformStaticFactor +
        difficultyLevelData.platformMovingFactor &&
        rand <
        difficultyLevelData.platformStaticFactor +
        difficultyLevelData.platformMovingFactor +
        difficultyLevelData.platformOneLifeFactor
      ) {
        t = "platformOnelife"
      }
    }

    const platform = this.getFreePlatforms(t)[0];
    platform.setInUse(true)
    this.changeInUse()
    this.latestPlatform = platform;
    if (platform.type !== "platformOnelife") this.latestNonOneLifePlatform = platform;
    platform.setProgress(this.progress)


    if (position !== undefined) {
      vec3.copy(platform.position, position);
    } else {
      platform.position = vec3.fromValues(
        -0.5 + Math.random() * 1,
        this.y,
        0
      );
      platform.setY(this.y);
      this.y += Math.random() * (0.02 - 0.05) + 0.37;
    }

    return platform
  }

  changeInUse() {
    let inuse: Platform[] = [];
    this.platforms.forEach((platforms) => {
      const inusePlatforms = platforms.filter((platform) => platform.getInUse());
      inuse = inuse.concat(inusePlatforms);
    })

    this.inUsePlatforms = [...inuse];
  }

  preRender(cam: Camera): void {
    let deltaUp = Math.abs(this.y - cam.y);
    if (deltaUp < 2.3) {
      this.addPlatform();
      deltaUp = Math.abs(this.y - cam.y);
    }
    let inUseChanged = false;
    this.platforms.forEach((platforms) => {
      const inusePlatforms = platforms.filter((platform) => platform.getInUse());
      inusePlatforms.forEach((platform) => {
        platform.preRender();
        if (cam.y - platform.position[1] > 1.6) {
          platform.progress = this.progress;
          platform.setInUse(false);
          inUseChanged = true;
        }
      })
    });

    if (inUseChanged) {
      this.changeInUse()
    }
  }

  render(ctx: RenderContext): void {
    this.platforms.forEach((platforms) => {
      const inusePlatforms = platforms.filter((platform) => platform.getInUse());
      inusePlatforms.forEach((platform) => {
        platform.render(ctx);
      })
    })
  }
}
