import Goodie from "./Goodie";
import Time from "@webgl/Time";
import { vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import { RenderContext } from "@webgl/core/Renderer";
import { getLevel } from "@/services/models/DifficultyLevelModel";
import { GoodieRenderable, GoodieType } from "@/services/models/GoodieModel";
import PlatformManager from "./PlatformManager";

const DISTANCE_MIN_BETWEEN_BONUS = 0.5;

export default class GoodiesManager {
  goodies: Goodie[];
  platformManager: PlatformManager;
  timeStampLastBonus: number;
  timeStampLastSpawnTry: number;
  timeElapsedSinceLastBonus: number;
  progress: number;

  constructor(
    private goodiesRenderablesMap: Map<GoodieType, GoodieRenderable>
  ) { }

  start() {
    this.goodies = [];
    this.timeElapsedSinceLastBonus = 0;
    this.timeStampLastBonus = Time.time;
    this.timeStampLastSpawnTry = Time.time;
    this.progress = 0;
  }

  setPlatformManager(platformManager: PlatformManager) {
    this.platformManager = platformManager;
  }

  preRender(cam: Camera) {

    // ADD NEW GOODIES

    this.timeElapsedSinceLastBonus = Time.time - this.timeStampLastBonus;
    const timeElapsedSinceLastTry = Time.time - this.timeStampLastSpawnTry;
    const difficultyLevelData = getLevel(this.progress);
    if (
      this.timeElapsedSinceLastBonus > 2000 &&
      this.goodies.length < difficultyLevelData.maxGoodieNumber &&
      timeElapsedSinceLastTry > 2000
    ) {
      this.addGoodies();
    }

    // REMOVE PAST GOODIES

    this.goodies.forEach((goodie) => {
      goodie.preRender();
      if (cam.y - goodie.position[1] > 1.6) {
        this.goodies.shift();
      }
    });
  }

  render(ctx: RenderContext): void {
    this.goodies.forEach((goodie) => {
      goodie.render(ctx);
    });
  }

  checkDistanceBetweenBonus() {
    return (
      this.goodies.length > 0 &&
      vec3.distance(
        this.platformManager.latestPlatform.position,
        this.goodies[this.goodies.length - 1].position
      ) < DISTANCE_MIN_BETWEEN_BONUS
    );
  }

  addGoodies() {
    const randEnemyFactor = Math.random();

    const difficultyLevelData = getLevel(this.progress);

    const spawnPlatform = this.platformManager.latestNonOneLifePlatform || this.platformManager.latestPlatform

    if (randEnemyFactor < difficultyLevelData.goodieFactor) {
      const rand = Math.random();
      this.timeStampLastSpawnTry = Time.time;
      //Check if there is a platform already linked
      if (
        this.goodies.length > 0 &&
        this.goodies[this.goodies.length - 1].linkedPlatform ===
        this.platformManager.latestNonOneLifePlatform
      )
        return;

      //Spring
      if (rand < difficultyLevelData.goodieWaterDropletFactor) {
        //Check distance between last platform and last bonus
        if (this.checkDistanceBetweenBonus()) return;

        this.goodies.push(
          new Goodie(
            this.goodiesRenderablesMap.get("goodieWaterDroplet"),
            spawnPlatform
          )
        );
        this.timeStampLastBonus = Time.time;
      }

      //Helico
      else if (
        rand > difficultyLevelData.goodieWaterDropletFactor &&
        rand <
        difficultyLevelData.goodieSkinTintFactor +
        difficultyLevelData.goodieWaterDropletFactor
      ) {
        //Check distance between last platform and last bonus
        if (this.checkDistanceBetweenBonus()) return;

        this.goodies.push(
          new Goodie(this.goodiesRenderablesMap.get("goodieSkinTint"),
            spawnPlatform)
        );
        this.timeStampLastBonus = Time.time;
      }

      //Invert
      else if (
        rand >
        difficultyLevelData.goodieWaterDropletFactor +
        difficultyLevelData.goodieSkinTintFactor &&
        rand <
        difficultyLevelData.goodieSkinTintFactor +
        difficultyLevelData.goodieWaterDropletFactor +
        difficultyLevelData.goodieMirrorFactor
      ) {
        //Check distance between last platform and last bonus
        if (this.checkDistanceBetweenBonus()) return;

        this.goodies.push(
          new Goodie(this.goodiesRenderablesMap.get("goodieMirror"),
            spawnPlatform)
        );
        this.timeStampLastBonus = Time.time;
      }
    }
  }
}
