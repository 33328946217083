var fn = function( obj ){
var __t,__p='';
__p+='attribute vec4 aPosition;\nuniform mat4 uVP;\nuniform mat4 uM;\n\nuniform float uScroll;\n\n#if __VERSION__ == 300\n    #define IN in\n    #define OUT out\n#else\n    #define IN attribute\n    #define OUT varying\n#endif\n\nOUT float vAlpha;\nOUT float vColor;\n\nvoid main(void) {\nfloat depth = aPosition.z;\nvec3 pos = vec3(aPosition.x, 5. - mod(aPosition.y + uScroll * depth, 1.) * 10., aPosition.z * 4.);\ngl_Position = uVP * uM * vec4(pos, 1.0);\ngl_PointSize = max(4. * depth, 1.);\nvAlpha = aPosition.w;\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
