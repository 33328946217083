var fn = function( obj ){
var __t,__p='';
__p+='';
 if(obj.slot === 'prelightsf' ){ 
__p+='\n\nvec3 nrm = normalize(vWorldNormal);\nfloat d = min(1.0, max( dot(geometryData.viewDir, nrm), 0.0 ));\nfloat f = FresnelParams().z + pow(1.0 - d, FresnelParams().x) * FresnelParams().y;\n\nsurface.emission = FresnelColor() * f * FresnelIntensity();\nsurface.alpha = f * (FresnelIntensity());\n//surface.albedo = vec3(0.0);\n// surface.specular = vec3(0.0, 0.0, 0.0);\n#if FresnelFadeUV\nfloat progress = saturate(FresnelIntensity() * 55.0 - vTexCoord().x * 50.0);\nsurface.emission *= 1.0 - clamp(1.0 - progress, 0.0, 1.0);\n#endif\n\n';
 } 
__p+='\n\n';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
