import PlatformReflection from "./Platform";
import { quat, vec3 } from "gl-matrix";
import { RenderContext } from "@webgl/core/Renderer";
import { GoodieRenderable } from "@/services/models/GoodieModel";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";


export default class Goodie {
  position: vec3;
  renderable: MeshRenderer;
  noRender = false;

  rotateY = 0;
  rotateX = 0;

  rotateYSpeed = 0;
  rotateXSpeed = 0;

  constructor(
    public goodieRenderable: GoodieRenderable,
    public linkedPlatform: PlatformReflection = null
  ) {
    this.position = vec3.create();
    this.renderable = this.goodieRenderable.renderer;
    this.rotateXSpeed = Math.PI / 200 + (Math.random() * Math.PI) / 200;
    this.rotateYSpeed = Math.PI / 200 + (Math.random() * Math.PI) / 200;
  }

  checkBump() {
    this.noRender = true;
  }

  preRender(): void {
    if (this.linkedPlatform != null) {
      vec3.copy(this.position, this.linkedPlatform.position);
      this.position[2] -= 0.1;
    }
    this.rotateY += this.rotateYSpeed;
    //this.rotateX += this.rotateXSpeed;
  }

  render(ctx: RenderContext): void {
    if (this.noRender) return;
    vec3.copy(this.renderable.node.position, this.position);
    quat.identity(this.renderable.node.rotation);
    quat.rotateY(
      this.renderable.node.rotation,
      this.renderable.node.rotation,
      this.rotateY
    );
    quat.rotateZ(
      this.renderable.node.rotation,
      this.renderable.node.rotation,
      this.rotateX
    );
    this.renderable.node.invalidate();
    this.renderable.node.updateWorldMatrix();
    this.renderable.render(
      ctx.gl,
      ctx.camera,
      ctx.mask,
      ctx.pass,
      ctx.glConfig
    );
  }
}
