import Renderer from "@webgl/Renderer";
import Camera from "nanogl-camera";
import Node from "nanogl-node";
import Vao from "nanogl-vao";
import ArrayBuffer from "nanogl/arraybuffer";
import Program from "nanogl/program";

import particleVert from "@webgl/glsl/particles/particle.vert"
import particleFrag from "@webgl/glsl/particles/particle.frag"
import { GLContext } from "nanogl/types";
import gui from "@webgl/dev/gui";
import GLState from "nanogl-state/GLState";
import GLConfig from "nanogl-state/GLConfig";

const PARTICLE_NUMBER = 100


function checkHighP(gl: GLContext) {

  const hv = gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT);
  const hf = gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT);
  return hf.precision > 0 && hv.precision > 0;

}

export class StarSystem {

  particleVao: Vao
  buffer: ArrayBuffer
  particleProgram: Program
  node: Node
  scroll: number
  hasHighp: boolean
  glstate: GLState
  config: GLConfig

  constructor(private renderer: Renderer, private cam: Camera, private systemX: number) {
    const gl = renderer.gl
    const partNumber = PARTICLE_NUMBER
    const particleArr = new Float32Array(partNumber * 4)

    for (let i = 0; i < partNumber; i++) {
      particleArr[i * 3 + 0] = Math.sign(systemX) * Math.random() * 0.3
      particleArr[i * 3 + 1] = Math.random() * 2 - 1
      particleArr[i * 3 + 2] = Math.random()
      particleArr[i * 3 + 3] = 0.15 + Math.random() * 0.85
    }

    this.buffer = new ArrayBuffer(gl, particleArr)
    this.buffer.attrib('aPosition', 4, gl.FLOAT)

    this.node = new Node()
    this.node.x = this.systemX
    this.node.z = 2.3

    this.glstate = GLState.get(gl);

    this.config = new GLConfig()
      .enableCullface(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
      .depthMask(false)
      .enableDepthTest(false)



    this.hasHighp = checkHighP(gl);
    this.particleProgram = new Program(gl)

    this.compile()

/////////////////
/////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
//////////////
  }

  precision() {
    return this.hasHighp ? 'highp' : 'mediump';
  }


  compile() {
    let defs = '\n';

    defs += 'precision ' + this.precision() + ' float;\n';

    this.particleProgram.compile(
      particleVert(),
      particleFrag(),
      defs
    );
    this.particleProgram.use();
  }

  preRender(scroll: number) {
    this.scroll = scroll
    this.node.y = scroll
    this.node.updateWorldMatrix()

  }

  render() {
    this.glstate.push(this.config);
    this.glstate.apply();
    this.particleProgram.use()
    this.particleProgram.uM(this.node._wmatrix)
    this.particleProgram.uVP(this.cam._viewProj)
    this.particleProgram.uScroll(this.scroll)

    // if (this.isFront) this.particleProgram.uParticleTex(this.partTex)

    this.buffer.attribPointer(this.particleProgram)
    this.buffer.drawPoints()


    this.glstate.pop()
    this.glstate.apply();
  }


}