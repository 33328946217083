import type { GLContext } from "nanogl/types";
import type {
  ITextureRequest,
  ITextureOptions,
  ITextureRequestSource,
} from "./TextureRequest";
import { TextureResource } from "./TextureResource";

///////////////////////////
import {
  AssetName,
  AssetPath,
  TextureName,
  TexturePath,
} from "./AssetsIdentifiers";
export type AssetIdent = AssetName | AssetPath;
export type TextureIdent = TextureName | TexturePath;
/////////
/////////////////////////////////////////
///////////////////////////////////////////
//////////

type FileInfos = {
  initialPath: string;
  path: string;
  group: string;
  name: string;
  ext: string;
  meta?: string;
  lod: number;
};

function getFileContext() {
  return require.context("@/assets/webgl/", true, /.*/i);
}

const _files = getFileContext();
const _contextId = _files.id;

const _assetsByPath: Map<string, FileInfos> = new Map();
const _assetsByName: Map<string, FileInfos> = new Map();
const _assets: FileInfos[] = [];
const _textures: Map<string, TextureAsset> = new Map();

function parsePath(initialPath: string, path: string): FileInfos {
  initialPath = initialPath.substring(2, initialPath.length);
  const sep = initialPath.lastIndexOf("/");
  const group = initialPath.substring(0, sep);
  const filename = initialPath.substring(sep + 1, initialPath.length);

  const regexp = /^([^.]+)\.(\w+)(\.(.*))?/;
  const r = regexp.exec(filename);

  const lodexp = /(.+)_LOD(\d)/;
  const l = lodexp.exec(r[1]);

  const name = l ? l[1] : r[1];
  const lod = l ? parseInt(l[2]) : 0;
  const ext = r[2];
  const meta = r[4];

  // console.log( filename, lod, r)
  return {
    initialPath,
    path,
    group,
    name,
    ext,
    meta,
    lod,
  };
}

const CODECS_PRIORITY: Record<string, number> = {
  astc: 0,
  pvr: 1,
  dxt: 1,
  etc: 1,
  basis: 2,
  webp: 3,
  std: 4,
};

function getTextureCodec(fileInfos: FileInfos): string {
  switch (fileInfos.meta) {
    case "astc.ktx":
      return "astc";
    case "pvr.ktx":
      return "pvr";
    case "dxt.ktx":
      return "dxt";
    case "etc.ktx":
      return "etc";
    case "basis.ktx2":
      return "basis";
    case "webp":
      return "webp";
    case undefined:
      return "std";
  }
  throw new Error("unsupported file " + fileInfos.initialPath);
}

function sortTexSources(
  sa: ITextureRequestSource,
  sb: ITextureRequestSource
): number {
  return CODECS_PRIORITY[sa.codec] - CODECS_PRIORITY[sb.codec];
}

class TextureAsset implements ITextureRequest {
  options: ITextureOptions;
  sources: ITextureRequestSource[] = [];

///////////////
/////////////////////////////////////
////////////

  addSource(fileInfos: FileInfos) {
    const codec = getTextureCodec(fileInfos);
    let requestSource = this.sources.find((s) => s.codec === codec);

    if (!requestSource) {
      requestSource = {
        codec,
        lods: [],
      };
      this.sources.push(requestSource);
    }

    requestSource.lods[fileInfos.lod] = { files: [fileInfos.path] };

    this.sources.sort(sortTexSources);
  }
}

function isTexture(fileInfos: FileInfos) {
  const ext = fileInfos.ext;
  return ext === "jpg" || ext === "png";
}

function handleFile(initialPath: string, path: string) {
  const fileInfos = parsePath(initialPath, path);
  _assets.push(fileInfos);
  _assetsByPath.set(fileInfos.initialPath, fileInfos);
  _assetsByName.set(fileInfos.name, fileInfos);
  if (isTexture(fileInfos)) {
    handleTexture(fileInfos);
  }
}

function handleTexture(fileInfos: FileInfos) {
  const resId = fileInfos.group + "/" + fileInfos.name;
  let tex = _textures.get(resId);
  if (!tex) {
    tex = new TextureAsset();
    _textures.set(resId, tex);
  }
  tex.addSource(fileInfos);
}

function getAssetInfos(filenameOrName: AssetIdent | string): FileInfos {
  let res = _assetsByPath.get(filenameOrName);
  if (!res) {
    res = _assetsByName.get(filenameOrName);
    if (!res) {
      console.error(`can't find asset ${filenameOrName}`);
    }
  }
  return res;
}

export default class AssetDatabase {
  static getAssets(): FileInfos[] {
    return _assets.concat();
  }

  static getAssetPath(filename: AssetIdent): string;
  static getAssetPath(filename: string): string;
  static getAssetPath(filename: AssetIdent | string): string {
    return getAssetInfos(filename).path;
  }

  static getTexture(
    filename: TextureIdent,
    gl: GLContext,
    options?: Partial<ITextureOptions>
  ): TextureResource;
  static getTexture(
    filename: string,
    gl: GLContext,
    options?: Partial<ITextureOptions>
  ): TextureResource;
  static getTexture(
    filename: TextureIdent | string,
    gl: GLContext,
    options?: Partial<ITextureOptions>
  ): TextureResource {
    const infos = getAssetInfos(filename);
    const res = _textures.get(infos.group + "/" + infos.name);
    if (!res) {
      console.error(`can't find texture ${filename}`);
    }
    const tr = new TextureResource(res, gl, options);

/////////////////
////////////////////////////
//////////////

    return tr;
  }

  /**
   * Debug only, print informations on assets available in WebglAssets
   */
  static printAssets(): void {
/////////////////
/////////////////////////////////////////////////
//////////////////////////////////
/////////////////////////////////
/////
///////////////////////////
//////////////////////////////////////
//////////////
  }
}

const deps: string[] = [];

_files.keys().forEach((k) => {
  deps.push(k);
  handleFile(k, _files(k).default);
});

// validate Texture Sources
// check if lods are missing
for (const tex of _textures.values()) {
  tex.sources.forEach((s) => {
    if (s.lods.includes(undefined)) {
      console.error(`texture has missing lods`, s.lods);
    }
  });
}

// console.log(_assetsByName);
// console.log( Array.from(_assetsByName.values()).map(i=>i.initialPath) )

// =============================================================================
//                  ==============  HMR  ================
// =============================================================================

/////////////

////////////////////////////////////////////////
//////////////////////////////
//////////////////////////////////////////////////////
////////////////////////////////////////
//////////////////////////////////
////////////////////////////////////////
////////////////////////////////////////
/////////////////////////
/////////
 

////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
/////////////
 

/////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
////////////////////
/////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
///////////////////////////////////////////
//////////////////////////////////////////
 

//////////////////////////////////////////////////////////////
/////////////////////////////////////////////////
//////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
///
/////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
///////////////////////////////
//////////////////////////
//////////////////////////////////////////////////////////////
/////////////////
////////
/////
///
 

/////////////////
/////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////////////
////////////////////////////////////////////////////
///////
/////
 

//////////
