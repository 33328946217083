import Player from "./Player";
import { quat, vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import Bounds from "nanogl-pbr/Bounds";
import Renderer from "@webgl/Renderer";
import Gltf from "nanogl-gltf/lib/Gltf";
import { Expo, Power2, gsap } from "gsap";
import EnemyManager from "./EnemyManager";
import GoodiesManager from "./GoodiesManager";
import AppService from "@/services/AppService";
import PlatformManager from "./PlatformManager";
import RenderPass from "@webgl/core/RenderPass";
import Node from "nanogl-gltf/lib/elements/Node";
import AudioManager from "@/core/audio/AudioManager";
import Gradient from "@webgl/glsl/gradient/Gradient";
import { RenderContext } from "@webgl/core/Renderer";
import { Activity } from "@webgl/activities/Activity";
import GltfResource from "@webgl/resources/GltfResource";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import SurfaceParallax from "@webgl/glsl/surface-parallax/SurfaceParallax";
import MaterialOverrideExtension from "nanogl-gltf/lib/extensions/MaterialOverrideExtension";
import enemyRenderablesMap, {
  setEnemyRenderer,
} from "@/services/models/EnemyModel";
import goodiesRenderablesMap, {
  setGoodieRenderer,
} from "@/services/models/GoodieModel";
import platformRenderablesMap, {
  setPlatformRenderer,
} from "@/services/models/PlatformModel";
import Time from "@webgl/Time";
import { StandardPass } from "nanogl-pbr/StandardPass";
import Lighting from "@webgl/engine/Lighting";
import Material from "nanogl-pbr/Material";
import { MetalnessSurface } from "nanogl-pbr/PbrSurface";
import MaterialOverrides from "./MaterialOverrides";
import Delay from "@/core/Delay";
import GridManager from "./GridManager";
import gridRenderablesMap, { setGridRenderer } from "@/services/models/GridModel";
import platformReflectionRenderablesMap, { setPlatformReflectionRenderer } from "@/services/models/PlatformReflectionModel";
import { toStatePaths } from "xstate/lib/utils";
import lerpRemap from "@/utils/LerpRemap";
import { StarSystem } from "./StarSystem";
import Viewport from "@/store/modules/Viewport";

const path = "game/game.gltf";

const START_Y = 0.1;

export default class Game1 implements Activity {
  gltf: Gltf;

  resource: GltfResource;

  player: Player;
  maxPlayerY = 0;

  platformManager: PlatformManager;
  goodiesManager: GoodiesManager;
  enemyManager: EnemyManager;
  gridManager: GridManager;

  lighting: Lighting;

  parallax: SurfaceParallax;

  focus: number;

  background: Node;
  backgroundY: number;

  score: number;
  progress: number; //Score over 1000 max 100 000
  gradient: Gradient;
  gradientRes: any;
  gradientTime: any;

  starSystemLeft: StarSystem
  starSystemRight: StarSystem

  paused = false;

  materialOverrides: MaterialOverrides;

  previousState = "";

  isPodDisplayed = true;
  portalRight: Node;
  portalRightSub1: Node;
  portalRightSub2: Node;
  portalLeft: Node;
  portalLeftSub1: Node;
  portalLeftSub2: Node;

  constructor(private renderer: Renderer) { }


  async load(): Promise<any> {
    this.lighting = new Lighting(this.renderer.gl);
    await this.lighting.load();


    const overrides = new MaterialOverrideExtension();
    this.materialOverrides = new MaterialOverrides(overrides);

    /* BACKGROUND MAT */
    //matOverrided.backgroundovveride()
    this.gradient = new Gradient();
    this.gradientTime = this.gradient.time.attachUniform();
    this.gradientTime.set(0);
    this.materialOverrides.overrideGradient("Background", this.gradient);

    /* PLATFORM MAT */

    await this.materialOverrides.overrideMatCap("PlatformStatic_Mat", "matcap_platforms-green", this.renderer.gl);
    await this.materialOverrides.overrideMatCap("PlatformSmall_Mat", "matcap_platforms-green", this.renderer.gl);
    await this.materialOverrides.overrideMatCap("PlatformOneLife_Mat", "matcap_platforms-ghost", this.renderer.gl);
    await this.materialOverrides.overrideMatCapWithGradientAlpha("PlatformStaticReflection_Mat", "matcap_platforms-green", "gradient", this.renderer.gl);
    await this.materialOverrides.overrideMatCapWithGradientAlpha("PlatformSmallReflection_Mat", "matcap_platforms-green", "gradient", this.renderer.gl);
    await this.materialOverrides.overrideMatCapWithGradientAlpha("PlatformOneLifeReflection_Mat", "matcap_platforms-ghost", "gradient", this.renderer.gl);

    /* GOODIES MAT */

    await this.materialOverrides.overrideMatCap("BonusWaterDroplet_Mat", "matcap_water_droplet", this.renderer.gl);
    await this.materialOverrides.overrideMatCap("BonusMirror_Mat", "matcap_mirror", this.renderer.gl);
    await this.materialOverrides.overrideMatCap("BonusMirrorHand_Mat", "matcap_mirror-handle", this.renderer.gl);

    /* GRID MAT */
    await this.materialOverrides.overrideGrid("GridFront_Mat", "grid", this.renderer.gl);
    await this.materialOverrides.overrideGrid("GridSide_Mat", "grid-sides", this.renderer.gl);

    /* PORTAL MAT */
    await this.materialOverrides.overridePortal("Portal_Mat", "portal", this.renderer.gl);

    /* NEON MAT */
    await this.materialOverrides.overrideNeon("neon_Mat", "neon", this.renderer.gl);
    await this.materialOverrides.overrideNeonWhite("neonHighlight_Mat", "neonWhite", this.renderer.gl);

    this.resource = new GltfResource(path, this.renderer.gl, {
      defaultTextureFilter: this.renderer.gl.LINEAR_MIPMAP_LINEAR,
      extensions: [overrides],
    });
    this.player = new Player();
    await this.player.load(this.renderer, this.materialOverrides);

    this.gltf = await this.resource.load();
    // await this.parallax.load();
    this.gltf.root.add(this.lighting.root);

    this.score = 0;
    this.progress = 0;
    this.onLoaded();
  }

  computeStaticBounds(out: Bounds) {
    this.gltf.root.updateWorldMatrix();
    const b: Bounds = new Bounds();
    Bounds.transform(
      out,
      this.gltf.renderables[0].bounds,
      this.gltf.renderables[0].node._wmatrix
    );
    for (const renderable of this.gltf.renderables) {
      Bounds.transform(b, renderable.bounds, renderable.node._wmatrix);
      Bounds.union(out, out, b);
    }
  }

  setupLighting() {
    this.lighting.lightSetup.prepare(this.renderer.gl);
    const materials = [] as Material[];
    for (const renderable of this.gltf.renderables) {
      for (const mat of renderable.materials) {
        if (materials.indexOf(mat) === -1) {
          materials.push(mat);
        }
      }
    }
    for (const material of materials) {
      this.lighting.setupMaterial(material);
    }
  }

  onLoaded(): void {

    /* CAMERA FOV */

    const isDesktop = window.innerWidth >= 1050 || !("ontouchstart" in window);
    let focus = 1.34;
    if (!isDesktop) {
      focus = 0.77;
    }
    (this.renderer.cameras.camera.lens as PerspectiveLens).setVerticalFov(
      focus
    );

    /* NEONS */
    const neonLeft = this.gltf.getNode("neonLeft");
    const neonRight = this.gltf.getNode("neonRight");
    const neonLeftWhite = this.gltf.getNode("neonLeftWhite");
    const neonRightWhite = this.gltf.getNode("neonRightWhite");

    neonLeft._parent.remove(neonLeft);
    neonRight._parent.remove(neonRight);
    this.renderer.camera.add(neonLeft);
    this.renderer.camera.add(neonRight);

    neonLeft.x = 0.62;
    neonLeft.y = 0;
    neonLeft.z = -1.18;
    neonLeft.setScale(3)

    neonRight.x = -0.62;
    neonRight.y = 0;
    neonRight.z = -1.18;
    neonRight.setScale(3)

    neonLeftWhite._parent.remove(neonLeftWhite);
    neonRightWhite._parent.remove(neonRightWhite);
    this.renderer.camera.add(neonLeftWhite);
    this.renderer.camera.add(neonRightWhite);

    neonLeftWhite.x = 0.62;
    neonLeftWhite.y = 0;
    neonLeftWhite.z = -1.1799;
    neonLeftWhite.setScale(1)

    neonRightWhite.x = -0.62;
    neonRightWhite.y = 0;
    neonRightWhite.z = -1.1799;
    neonRightWhite.setScale(1)

    /* BACKGROUND PARALLAX PLANE */

    const plane = this.gltf.getNode("backgroundGradient");
    plane._parent.remove(plane);

    this.renderer.camera.add(plane);
    plane.z = -1.8;
    plane.y = -0.3;
    plane.invalidate();
    plane.updateWorldMatrix();
    plane.setScale(1.13);

    const backgroundStars = this.gltf.getNode("backgroundStars");
    backgroundStars._parent.remove(backgroundStars);
    this.renderer.camera.add(backgroundStars);
    backgroundStars.z = -2.4;
    backgroundStars.y = -0.3;
    backgroundStars.invalidate();
    backgroundStars.updateWorldMatrix();
    backgroundStars.setScale(1.5);

    const pod = this.gltf.getNode("pod");
    pod.z = -0.4;

    /* PORTALS */
    this.portalRight = this.gltf.getNode("portalRight");
    this.portalRightSub1 = this.gltf.getNode("portalRightSub1");
    this.portalRightSub2 = this.gltf.getNode("portalRightSub2");
    this.portalRight.x = -0.6;
    this.portalRightSub1.x = -0.62;
    this.portalRightSub2.x = -0.64;
    this.portalRight.z = -0.18;
    this.portalRightSub1.z = -0.18;
    this.portalRightSub2.z = -0.18;
    this.portalRight.scale[1] = 1.5;

    this.portalLeft = this.gltf.getNode("portalLeft");
    this.portalLeftSub1 = this.gltf.getNode("portalLeftSub1");
    this.portalLeftSub2 = this.gltf.getNode("portalLeftSub2");
    this.portalLeft.x = 0.6;
    this.portalLeftSub1.x = 0.62;
    this.portalLeftSub2.x = 0.64;
    this.portalLeft.z = -0.18;
    this.portalLeftSub1.z = -0.18;
    this.portalLeftSub2.z = -0.18;
    this.portalLeft.scale[1] = 1.5;


    /* LIGHTING */

    this.setupLighting();
    this.player.setupLighting(this.lighting, this.renderer);

    /* SET MANAGERS */

    setPlatformRenderer(this.gltf);
    setPlatformReflectionRenderer(this.gltf)
    this.platformManager = new PlatformManager(platformRenderablesMap, platformReflectionRenderablesMap);

    setGoodieRenderer(this.gltf);
    this.goodiesManager = new GoodiesManager(goodiesRenderablesMap);

    setEnemyRenderer(this.gltf);
    this.enemyManager = new EnemyManager(enemyRenderablesMap);

    setGridRenderer(this.gltf);
    this.gridManager = new GridManager(gridRenderablesMap);

    this.starSystemRight = new StarSystem(this.renderer, this.renderer.camera, -6.39)
    this.starSystemLeft = new StarSystem(this.renderer, this.renderer.camera, 6.39)

    /* SET PLAYER */

    this.player.onLoaded(
      this.platformManager,
      this.goodiesManager,
      this.enemyManager,
      this.gltf.getNode("SpeedSphere").renderable,
      this.gradient,
    );

    /* SET BACKGROUND (WALLS) */

    this.background = this.gltf.getNode("grids")._parent as Node;
    this.backgroundY = this.background.position[1];
    this.background.z = 0.5;

    this.focus = this.renderer.cameras.camera.lens._fov;

  }

  unload(): void { }

  start(): void {

    /* CAMERA START */
    if (!Viewport.isDesktop) {
      const rootElement = document.documentElement;
      rootElement.classList.add("prevent-select")
    }

    const cam: Camera = this.renderer.cameras.camera;
    vec3.set(cam.position, 0, 0, -1);
    cam.lookAt(this.gltf.getNode("root").position);
    cam.invalidate();
    cam.updateWorldMatrix();
    cam.updateViewProjectionMatrix(
      this.renderer.viewport.width,
      this.renderer.viewport.height
    );



    /* PLAYER START */

    this.player.start();

    this.maxPlayerY =
      this.player.node.y =
      this.player.prevY =
      START_Y;

    /* GAME START */

    this.platformManager.start();
    this.goodiesManager.start();
    this.goodiesManager.setPlatformManager(this.platformManager);
    this.enemyManager.start();
    this.enemyManager.setPlatformManager(this.platformManager);
    this.enemyManager.addEnemy();
    this.gridManager.start();

    this.background.position[1] = this.backgroundY;
    this.background.invalidate();

    AudioManager.instance.setCanPlay();

    this.score = 0;
    this.progress = 0;

    this.isPodDisplayed = true;

    AppService.state.subscribe(this.changeState);

    document.addEventListener("visibilitychange", this._onVisibilityChange);

  }

  stop(): void {
    const rootElement = document.documentElement;
    rootElement.classList.remove("prevent-select")
    this.player.stop();
    this.platformManager.stop();
    this.gridManager.stop();

    // this.parallax.stop();

    const cam: Camera = this.renderer.cameras.camera;
    vec3.set(cam.position, 0, 0, -3);
    cam.lookAt(this.gltf.getNode("root").position);
    cam.invalidate();

    document.removeEventListener("visibilitychange", this._onVisibilityChange);
  }

  _onVisibilityChange = () => {
    if (document.visibilityState === "visible" && this.previousState !== "game.idle.paused") {
      this.paused = false;
      AudioManager.instance.resumeAmbient();
    } else {
      this.paused = true;
      AudioManager.instance.pauseAmbient();
    }
  }

  changeState = async (state: any) => {
    if (this.previousState === toStatePaths(state.value)[0].join(".")) return;
    else this.previousState = toStatePaths(state.value)[0].join(".");

    this.player.changeState(state);

    if (state.matches("game.idle")) {
      if (state.matches("game.idle.paused")) {
        this.paused = true;
        AudioManager.instance.pauseAmbient();
      }
      else {
        this.paused = false;
        AudioManager.instance.resumeAmbient();
      }
    }
    if (state.matches("game.idle.playing.intro")) {
      const focus = { fov: 0.74 };
      (this.renderer.cameras.camera.lens as PerspectiveLens).setVerticalFov(
        focus.fov
      );
      gsap.to(focus, {
        fov: 1.34,
        delay: 1.,
        duration: 2,
        ease: "power2.easeInOut",
        onUpdate: () => {
          (this.renderer.cameras.camera.lens as PerspectiveLens).setVerticalFov(
            focus.fov
          );
        }
      });

    }
    if (state.matches("game.idle.playing.gameplay")) {
      //Hide pod after intro
      this.isPodDisplayed = false;
    }
  }

  preRender(): void {
    if (this.paused) return;
    /* GENERAL UPDATE */

    this.player.preRender(this.renderer);
    this.platformManager.preRender(this.renderer.camera);
    this.goodiesManager.preRender(this.renderer.camera);
    this.enemyManager.preRender(this.renderer.camera);
    this.gridManager.preRender(this.renderer.camera);
    //this.parallax.preRender(this.renderer.camera.y + 2);

    this.gltf.root.updateWorldMatrix();

    this.renderer.camera.y = this.maxPlayerY;

    this.portalRight.y = this.player.node.y + 0.1;
    this.portalRightSub1.y = this.player.node.y + 0.1;
    this.portalRightSub2.y = this.player.node.y + 0.1;
    const distanceRight = this.player.node.x - this.portalRight.x;

    const normalizedDistance = Math.min(Math.max((distanceRight) / 0.2, 0), 1);
    //console.log(normalizedDistance);

    const remappedScale = lerpRemap(1, 0.8, 0, 1, normalizedDistance);
    const remappedScaleRightSub1 = lerpRemap(1, 0.78, 0, 1, normalizedDistance);
    const remappedScaleRightSub2 = lerpRemap(1, 0.76, 0, 1, normalizedDistance);
    this.portalRight.scale[0] = Math.min(remappedScale, 1);
    this.portalRight.scale[1] = Math.min(remappedScale,1) * 1.5;
    this.portalRight.scale[2] = Math.min(remappedScale, 1);
    this.portalRightSub1.scale[0] = Math.min(remappedScaleRightSub1, 1);
    this.portalRightSub1.scale[1] = Math.min(remappedScaleRightSub1, 1) * 1.5;
    this.portalRightSub1.scale[2] = Math.min(remappedScaleRightSub1, 1);
    this.portalRightSub2.scale[0] = Math.min(remappedScaleRightSub2, 1);
    this.portalRightSub2.scale[1] = Math.min(remappedScaleRightSub2, 1) * 1.5;
    this.portalRightSub2.scale[2] = Math.min(remappedScaleRightSub2, 1);
    
    this.portalRight.invalidate();

    this.portalLeft.y = this.player.node.y + 0.1;
    this.portalLeftSub1.y = this.player.node.y + 0.1;
    this.portalLeftSub2.y = this.player.node.y + 0.1;
    const distanceLeft = Math.abs(this.player.node.x - this.portalLeft.x);
    const normalizedDistanceLeft = Math.min(Math.max((distanceLeft) / 0.2, 0.0), 1);

    const remappedScaleLeft = lerpRemap(1, 0.8, 0, 1, normalizedDistanceLeft);
    const remappedScaleLeftSub1 = lerpRemap(1, 0.78, 0, 1, normalizedDistanceLeft);
    const remappedScaleLeftSub2 = lerpRemap(1, 0.76, 0, 1, normalizedDistanceLeft);
    this.portalLeft.scale[0] = Math.min(remappedScaleLeft, 1);
    this.portalLeft.scale[1] = Math.min(remappedScaleLeft,1) * 1.5;
    this.portalLeft.scale[2] = Math.min(remappedScaleLeft, 1);
    this.portalLeftSub1.scale[0] = Math.min(remappedScaleLeftSub1, 1);
    this.portalLeftSub1.scale[1] = Math.min(remappedScaleLeftSub1,1) * 1.5;
    this.portalLeftSub1.scale[2] = Math.min(remappedScaleLeftSub1, 1);
    this.portalLeftSub2.scale[0] = Math.min(remappedScaleLeftSub2, 1);
    this.portalLeftSub2.scale[1] = Math.min(remappedScaleLeftSub2,1) * 1.5;
    this.portalLeftSub2.scale[2] = Math.min(remappedScaleLeftSub2, 1);

    this.portalLeft.invalidate();

    /* UPDATE SCORE AND PROGRESS */
    const deltaMaxPlayerY = this.player.node.y - this.maxPlayerY;
    if (deltaMaxPlayerY > 0) {
      this.score += deltaMaxPlayerY * 125 * this.player.scoreMultiplier;
    }
    if (Math.round(this.score) !== AppService.state.machine.context.score) {
      AppService.state.send({
        type: "UPDATE_SCORE",
        score: Math.round(this.score),
      });
    }

    this.progress = Math.min(this.score / 100, 100);

    this.enemyManager.progress = this.progress;
    this.platformManager.progress = this.progress;
    this.goodiesManager.progress = this.progress;

    /* UPDATE MAX PLAYER POSITION */

    this.maxPlayerY = Math.max(this.maxPlayerY, this.player.node.y);

    this.gradientTime.set(Time.time);

    this.starSystemLeft.preRender(this.maxPlayerY);
    this.starSystemRight.preRender(this.maxPlayerY);
  }

  rttPass(): void {
    this.lighting.lightSetup.prepare(this.renderer.gl);
    this.lighting.renderLightmaps((ctx: RenderContext) => {
      this.render(ctx);
    });
  }

  render(ctx: RenderContext): void {
    this.platformManager.render(ctx);
    this.goodiesManager.render(ctx);
    this.enemyManager.render(ctx);
    this.gridManager.render(ctx);
    this.player.render(ctx);
    for (const renderable of this.gltf.renderables) {
      if (
        renderable.node.name !== "platformStatic" &&
        renderable.node.name !== "platformSmall" &&
        renderable.node.name !== "platformOnelife" &&
        renderable.node.name !== "platformStatic_reflect" &&
        renderable.node.name !== "platformSmall_reflect" &&
        renderable.node.name !== "platformOnelife_reflect" &&
        renderable.node.name !== "goodieWaterDroplet" &&
        renderable.node.name !== "goodieSkinTint" &&
        renderable.node.name !== "goodieMirror" &&
        renderable.node.name !== "copyCat" &&
        renderable.node.name !== "SpeedSphere" &&
        renderable.node.name !== "grids"  &&
        renderable.node.name !== "grid_front" &&
        renderable.node.name !== "grid_side_left" &&
        renderable.node.name !== "grid_side_right" &&
        renderable.node.name !== "mirror_hand_2" &&
        renderable.node.name !== "BB_optimized" &&
        renderable.node.name !== "pod" &&
        renderable.node.name !== "CheckDisk"
      ) {
        renderable.render(ctx.gl, ctx.camera, ctx.mask, ctx.pass, ctx.glConfig);
      }
      else if(renderable.node.name === "pod" && this.isPodDisplayed){
        renderable.render(ctx.gl, ctx.camera, ctx.mask, ctx.pass, ctx.glConfig);
      }
    }

    this.starSystemLeft.render();
    this.starSystemRight.render();
  }
}
