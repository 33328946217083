import { reactive } from 'vue'

type ScoreItem = {
  id: string
  score: number
  position: number
  username: string
}
type Scores = {
  leaderboard: Array<ScoreItem>
  me?: ScoreItem
}

export const Scores = reactive<Scores>({
  leaderboard: [],
  me: null
})

// Mock data:
// export const Scores = reactive<Scores>({
//   leaderboard: [
//     {
//         "id": "lohRVgPnQlt9NVzeUf87_",
//         "score": 9999999,
//         "position": 1,
//         "username": "ANT"
//     },
//     {
//         "id": "PA5KdWvJCsB06Ha1dQ5O4",
//         "score": 9999999,
//         "position": 2,
//         "username": "ANT"
//     },
//     {
//         "id": "reqSbakvlwrSAv2H_Axt-",
//         "score": 999999,
//         "position": 3,
//         "username": "ANT"
//     },
//     {
//         "id": "hZyvi7Bs60QutIiHZrHlg",
//         "score": 999999,
//         "position": 4,
//         "username": "ANT"
//     },
//     {
//         "id": "O21XrVYf1kWlxe8BNRdhe",
//         "score": 99999,
//         "position": 5,
//         "username": "ANT"
//     },
//     {
//         "id": "YSARLE7pLENlIgqzgYAnI",
//         "score": 9999,
//         "position": 6,
//         "username": "ANT"
//     },
//     {
//         "id": "bSF9VTLa2K4dZmE_XWGSp",
//         "score": 1901,
//         "position": 7,
//         "username": "ANT"
//     },
//     {
//         "id": "CBT_DaGU1Hga9R0nQjjf-",
//         "score": 1387,
//         "position": 8,
//         "username": "gui"
//     },
//     {
//         "id": "uvGceV0mA-cD0PXVi29YA",
//         "score": 1374,
//         "position": 9,
//         "username": "gui"
//     },
//     {
//         "id": "ensCrYFAtiZMUiHBwA06J",
//         "score": 68,
//         "position": 10,
//         "username": "tes"
//     }
//   ],
//   me: {
//     id: "ensCrYFAtiZMUiHBwA06J-",
//     position: 6,
//     score: 6938,
//     username: "tes"
//   }
// })