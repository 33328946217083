import Masks from "@webgl/gl/Masks";
import { GLContext } from "nanogl/types";
import { Sampler } from "nanogl-pbr/Input";
import TexCoord from "nanogl-pbr/TexCoord";
import UnlitPass from "nanogl-pbr/UnlitPass";
import RenderPass from "@webgl/core/RenderPass";
import Gradient from "@webgl/glsl/gradient/Gradient";
import { AlphaModes } from "nanogl-pbr/AlphaModeEnum";
import AssetDatabase from "@webgl/resources/AssetDatabase";
import MatcapPass from "@webgl/samples/custom_material/matcap/MatcapPass";
import MaterialOverrideExtension from "nanogl-gltf/lib/extensions/MaterialOverrideExtension";
import { MetalnessSurface } from "nanogl-pbr/PbrSurface";
import { StandardPass } from "nanogl-pbr/StandardPass";
import Portal from "@webgl/glsl/portal/Portal";

export default class MaterialOverrides {
  portal: Portal | null;
  constructor(
    public overrides : MaterialOverrideExtension 
  ) {
  }

  async overrideMatCap(materialName: string, matCapTextureName: string, gl : GLContext) {
    const matCapPass = new MatcapPass();
    const matcapTexture = AssetDatabase.getTexture(matCapTextureName, gl);
    await matcapTexture.load()
    matCapPass.matcap = matcapTexture.texture
    this.overrides.overridePass(materialName, matCapPass);
  }

  async overrideMatCapWithGradientAlpha(materialName: string, matCapTextureName: string, gradientAlphaTextureName: string, gl : GLContext) {
    const matCapPass = new MatcapPass();
    const matcapTexture = AssetDatabase.getTexture(matCapTextureName, gl);
    await matcapTexture.load()
    const gradientAlphaTexture = AssetDatabase.getTexture(gradientAlphaTextureName, gl);
    await gradientAlphaTexture.load()

    const sampler = new Sampler('basecolor', TexCoord.create('aTexCoord0'));
    sampler.set(gradientAlphaTexture.texture);
    matCapPass.alpha.attach(sampler)
    matCapPass.matcap = matcapTexture.texture
    //matCapPass.alphaFactor.attachConstant(0.65);

    matCapPass.alphaMode.set(AlphaModes[2]);
    matCapPass.glconfig.enableBlend()
    .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA )
    .enableCullface()
    .enableDepthTest()
    .depthMask(false)
    matCapPass.mask = Masks.BLENDED;

    this.overrides.overridePass(materialName, matCapPass);
  }

  overrideGradient(materialName: string, gradient: Gradient) {
    this.overrides.overridePass(materialName, (ctx, mat) => {
      const pass = mat.getPass(RenderPass.COLOR).pass as UnlitPass;
      pass.inputs.add(gradient);

      return null;
    });
  }

  async overrideNeon(materialName: string, neonTextureName: string, gl : GLContext) {
    const neonTextureAsset = AssetDatabase.getTexture(neonTextureName, gl);
    neonTextureAsset.setTransparent();
    await neonTextureAsset.load();

    this.overrides.overridePass(materialName,  (ctx, mat) => {
      const pass = mat.getPass(RenderPass.COLOR)
      .pass as StandardPass<MetalnessSurface>;
      const sampler = new Sampler('basecolor', TexCoord.create('aTexCoord0'));
      sampler.set(neonTextureAsset.texture);
      
      // pass.surface.baseColor.attach(sampler) 
      pass.surface.baseColor.attachConstant([1, 0.28, 0.439]);
      pass.surface.baseColorFactor.attachConstant([1, 0.28, 0.439]);
      pass.emissive.attachConstant([1, 0.28, 0.439])
      pass.emissiveFactor.attachConstant([1, 0.28, 0.7])

      pass.alpha.attach(sampler) 
      //pass.alphaFactor.attachConstant(0.65);
      // pass.alphaCutoff.attachConstant(0.5);

      pass.alphaMode.set(AlphaModes[2]);
      pass.glconfig.enableBlend()
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA )
      // .enableCullface()
      .enableDepthTest()
      .depthMask(false)
      pass.mask = Masks.BLENDED;

      return null;
    });
  }

  async overrideNeonWhite(materialName: string, neonTextureName: string, gl : GLContext) {
    const neonTextureAsset = AssetDatabase.getTexture(neonTextureName, gl);
    neonTextureAsset.setTransparent();
    await neonTextureAsset.load();

    this.overrides.overridePass(materialName,  (ctx, mat) => {
      const pass = mat.getPass(RenderPass.COLOR)
      .pass as StandardPass<MetalnessSurface>;
      const sampler = new Sampler('basecolor', TexCoord.create('aTexCoord0'));
      sampler.set(neonTextureAsset.texture);
      
      // pass.surface.baseColor.attach(sampler) 
      pass.surface.baseColor.attachConstant([1, 1, 1]);
      pass.emissive.attachConstant([1, 1, 1])
      pass.emissiveFactor.attachConstant([1, 1, 1])

      pass.alpha.attach(sampler) 
      pass.alphaFactor.attachConstant(1);
      pass.alphaCutoff.attachConstant(0.9);

      pass.alphaMode.set(AlphaModes[2]);
      pass.glconfig.enableBlend()
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA )
      // .enableCullface()
      .enableDepthTest()
      .depthMask(false)
      pass.mask = Masks.BLENDED;

      return null;
    });
  }

  async overrideGrid(materialName: string, gridTextureName: string, gl : GLContext) {
    const gridTexture = AssetDatabase.getTexture(gridTextureName, gl);
    gridTexture.setTransparent();
    await gridTexture.load();
    
    this.overrides.overridePass(materialName,  (ctx, mat) => {
      const pass = mat.getPass(RenderPass.COLOR).pass as UnlitPass;
      const sampler = new Sampler('basecolor', TexCoord.create('aTexCoord0'));
      sampler.set(gridTexture.texture);
      
      pass.baseColor.attachConstant([1, 1, 1, 1]);
      pass.baseColorFactor.attachConstant([0.737, 1, 0.782, 0.7]);
      pass.alpha.attach(sampler) 
      pass.alphaFactor.attachConstant(0.65);

      pass.alphaMode.set(AlphaModes[2]);
      pass.glconfig.enableBlend()
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA )
      .enableCullface()
      .enableDepthTest()
      .depthMask(false)
      pass.mask = Masks.BLENDED;

      return null;
    });
  }

  async overridePortal(materialName: string, portalTextureName: string, gl : GLContext) {
    const portalTexture = AssetDatabase.getTexture(portalTextureName, gl);
    portalTexture.setTransparent();
    await portalTexture.load();
    
    this.overrides.overridePass(materialName,  (ctx, mat) => {
      const pass = mat.getPass(RenderPass.COLOR).pass as UnlitPass;

      this.portal = new Portal();
      this.portal.setTexture(portalTexture.texture);
      pass.inputs.add(this.portal);

      pass.alphaMode.set(AlphaModes[2]);
      pass.glconfig.enableBlend()
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA )
      .enableCullface()
      .enableDepthTest()
      .depthMask(false)
      pass.mask = Masks.BLENDED;

      return null;
    });
  }
    
}
