import Lighting from "@webgl/engine/Lighting";
import IblResource from "@webgl/resources/IblResource";


/////////////
///

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
////////////////////////


/////////////////////////////////////////////////////////////////
//
////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////

////////////////////////////////////////////////////////////////
/////////////////
///////////////////////////
//////////////////////////////////
////////////////////////
////////////////////
//////////////
////

 

////
/////////
function _addDevIbls():void {}
//////////
//*/

export default function addDevIbls( lighting : Lighting ):void {
  _addDevIbls(lighting)
}
