import gsap from "gsap";
import gui from "@webgl/dev/gui";
import Chunk from "nanogl-pbr/Chunk";
import FragModule from './Gradient.frag'
import TexCoord from "nanogl-pbr/TexCoord";
import vec3 from "gl-matrix/src/gl-matrix/vec3";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import CreateShader from "@webgl/core/CreateProgram";
import Input, { ShaderType, Uniform } from "nanogl-pbr/Input";
import { ColorComponents3, convertColor } from "@tweakpane/core/dist/cjs/input-binding/color/model/color-model";

/**
 * this shader can be edited live and hot reloaded in place
 */
const FragCode = CreateShader(FragModule)

export default class Gradient extends Chunk {

  tw: gsap.core.Tween;
  tw2: gsap.core.Tween;
  tw3: gsap.core.Tween;
  tw4: gsap.core.Tween;
  
  intensity: Input;
  uvs: Input;
  resolution: Input;
  time: Input;

  // Color inputs
  color1: Input;
  color2: Input;
  color3: Input;
  color4: Input;

  // Color values
  color1Value = vec3.create();
  color2Value = vec3.create();
  color3Value = vec3.create();
  color4Value = vec3.create();
  
  // Color Uniforms
  color1uniform: Uniform;
  color2uniform: Uniform;
  color3uniform: Uniform;
  color4uniform: Uniform;

  // Color values for each gradient types IN HSL
  blueGradientColor1 = vec3.fromValues(0.6361, 1, 0.35);
  blueGradientColor2 = vec3.fromValues(0.6361, 1, 0.17);
  blueGradientColor3 = vec3.fromValues(0.625, 1, 0.13);
  blueGradientColor4 = vec3.fromValues(0.6361, 1, 0.52);

  greenGradientColor1 = vec3.fromValues(0.2333, 1, 0.6);
  greenGradientColor2 = vec3.fromValues(0.2222, 0.58, 0.52);
  greenGradientColor3 = vec3.fromValues(0.5083, 1, 0.63);
  greenGradientColor4 = vec3.fromValues(0.5083, 1, 0.67);

  blueGreenGradientColor1 = vec3.fromValues(0.488, 0.98, 0.47);
  blueGreenGradientColor2 = vec3.fromValues(0.5083, 0.66, 0.22);
  blueGreenGradientColor3 = vec3.fromValues(0.5083, 1, 0.36);
  blueGreenGradientColor4 = vec3.fromValues(0.5083, 1, 0.76);

  // Point inputs
  point1 : Input;
  point2 : Input;
  point3 : Input;
  point4 : Input;

  // Point values
  point3value;
  point1value;
  point2value;
  point4value;

  constructor(){
    super(true, false)

    this.addChild( this.uvs = new Input('vTexCoord', 2, ShaderType.FRAGMENT))
    //this.addChild(this.resolution = new Input('uResolution', 2, ShaderType.FRAGMENT))
    this.addChild(this.time = new Input('uTime', 1, ShaderType.FRAGMENT))
    this.addChild(this.color1 = new Input('uColor1', 3, ShaderType.FRAGMENT))
    this.addChild(this.color2 = new Input('uColor2', 3, ShaderType.FRAGMENT))
    this.addChild(this.color3 = new Input('uColor3', 3, ShaderType.FRAGMENT))
    this.addChild(this.color4 = new Input('uColor4', 3, ShaderType.FRAGMENT))
    this.addChild(this.point1 = new Input('uPoint1', 2, ShaderType.FRAGMENT))
    this.addChild(this.point2 = new Input('uPoint2', 2, ShaderType.FRAGMENT))
    this.addChild(this.point3 = new Input('uPoint3', 2, ShaderType.FRAGMENT))
    this.addChild(this.point4 = new Input('uPoint4', 2, ShaderType.FRAGMENT))


    // initialize these input with constant values
    this.uvs.attachAttribute(TexCoord.create("aTexCoord0").attrib);

    //Set colors values and uniforms
    this.color1Value.set([this.blueGradientColor1[0], this.blueGradientColor1[1], this.blueGradientColor1[2]]);
    this.color2Value.set([this.blueGradientColor2[0], this.blueGradientColor2[1], this.blueGradientColor2[2]]);
    this.color3Value.set([this.blueGradientColor3[0], this.blueGradientColor3[1], this.blueGradientColor3[2]]);
    this.color4Value.set([this.blueGradientColor4[0], this.blueGradientColor4[1], this.blueGradientColor4[2]]);

    this.color1uniform = this.color1.attachUniform('uColor1', 3)
    this.color2uniform = this.color2.attachUniform('uColor2', 3)
    this.color3uniform = this.color3.attachUniform('uColor3', 3)
    this.color4uniform = this.color4.attachUniform('uColor4', 3)

    this.color1uniform.set(this.color1Value[0], this.color1Value[1], this.color1Value[2]);
    this.color2uniform.set(this.color2Value[0], this.color2Value[1], this.color2Value[2]);
    this.color3uniform.set(this.color3Value[0], this.color3Value[1], this.color3Value[2]);
    this.color4uniform.set(this.color4Value[0], this.color4Value[1], this.color4Value[2]);

    //Set points values and uniforms
    this.point1value = {x : 0.0, y : 0.0}
    this.point2value = {x : 1.0, y : 0.0}
    this.point3value = {x : 0.0, y : 1.0}
    this.point4value = {x : 1.0, y : 1.0}

    const point1uniform = this.point1.attachUniform('uPoint1', 2)
    const point2uniform = this.point2.attachUniform('uPoint2', 2)
    const point3uniform = this.point3.attachUniform('uPoint3', 2)
    const point4uniform = this.point4.attachUniform('uPoint4', 2)

    point1uniform.set(this.point1value.x , this.point1value.y)
    point2uniform.set(this.point2value.x , this.point2value.y)
    point3uniform.set(this.point3value.x , this.point3value.y)
    point4uniform.set(this.point4value.x , this.point4value.y)

/////////////////
////////////////////////////////////

////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////

//////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////
//////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////
//////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////
//////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////
//////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////
//////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////
//////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////
//////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////

//////////////

    /**
     * invalidate this chunk's code when live reloading the glsl module
     * `onHmr` noop in non DEBUG mode
     */
    FragCode.onHmr(()=>this.invalidateCode())
    
  }

  public switchToBlue(duration = 1): void {
    
    // Tween first point color
    const color1InHSL = [this.color1Value[0], this.color1Value[1], this.color1Value[2]]
    const targetColor1 = this.blueGradientColor1;

    if(this.tw) this.tw.kill();
    this.tw = gsap.to(color1InHSL,
      {
      duration: duration,
      0: targetColor1[0],
      1: targetColor1[1],
      2: targetColor1[2],
      ease: "power2.inOut",
      onUpdate: () => {
        this.color1uniform.set(color1InHSL[0], color1InHSL[1], color1InHSL[2]);
      },
      onComplete: () => {
        this.color1Value.set([targetColor1[0], targetColor1[1], targetColor1[2]]);
        this.tw = null;
      }
    });

    // Tween second point color
    const color2InHSL = [this.color2Value[0], this.color2Value[1], this.color2Value[2]];
    const targetColor2 = this.blueGradientColor2;

    if(this.tw2) this.tw2.kill();
    this.tw2 = gsap.to(color2InHSL, {
      duration: duration,
      0: targetColor2[0],
      1: targetColor2[1],
      2: targetColor2[2],
      ease: "power2.inOut",
      onUpdate: () => {
        this.color2uniform.set(color2InHSL[0], color2InHSL[1], color2InHSL[2]);
      },
      onComplete: () => {
        this.color2Value.set([targetColor2[0], targetColor2[1], targetColor2[2]]);
        this.tw2 = null;
      }
    });

    // Tween third point color
    const color3InHSL =  [this.color3Value[0], this.color3Value[1], this.color3Value[2]];
    const targetColor3 = this.blueGradientColor3;

    if(this.tw3) this.tw3.kill();
    this.tw3 = gsap.to(color3InHSL, {
      duration: duration,
      0: targetColor3[0],
      1: targetColor3[1],
      2: targetColor3[2],
      ease: "power2.inOut",
      onUpdate: () => {
        this.color3uniform.set(color3InHSL[0], color3InHSL[1], color3InHSL[2]);
      },
      onComplete: () => {
        this.color3Value.set([targetColor3[0], targetColor3[1], targetColor3[2]]);
        this.tw3 = null;
      }
    });

    // Tween second point color
    const color4InHSL =  [this.color4Value[0], this.color4Value[1], this.color4Value[2]];
    const targetColor4 = this.blueGradientColor4;

    if(this.tw4) this.tw4.kill();
    this.tw4 = gsap.to(color4InHSL, {
      duration: duration,
      0: targetColor4[0],
      1: targetColor4[1],
      2: targetColor4[2],
      ease: "power2.inOut",
      onUpdate: () => {
        this.color4uniform.set(color4InHSL[0], color4InHSL[1], color4InHSL[2]);
      },
      onComplete: () => {
        this.color4Value.set([targetColor4[0], targetColor4[1], targetColor4[2]]);
        this.tw4 = null;
      }
    });
    
  }

  public switchToGreen(duration = 1): void {
    
    // Tween first point color
    const color1InHSL = [this.color1Value[0], this.color1Value[1], this.color1Value[2]]
    const targetColor1 = this.greenGradientColor1;

    if(this.tw) this.tw.kill();
    this.tw = gsap.to(color1InHSL,
      {
      duration: duration,
      0: targetColor1[0],
      1: targetColor1[1],
      2: targetColor1[2],
      ease: "power2.inOut",
      onUpdate: () => {
        this.color1uniform.set(color1InHSL[0], color1InHSL[1], color1InHSL[2]);
      },
      onComplete: () => {
        this.color1Value.set([targetColor1[0], targetColor1[1], targetColor1[2]]);
        this.tw = null;
      }
    });

    // Tween second point color
    const color2InHSL = [this.color2Value[0], this.color2Value[1], this.color2Value[2]];
    const targetColor2 = this.greenGradientColor2;

    if(this.tw2) this.tw2.kill();
    this.tw2 = gsap.to(color2InHSL, {
      duration: duration,
      0: targetColor2[0],
      1: targetColor2[1],
      2: targetColor2[2],
      ease: "power2.inOut",
      onUpdate: () => {
        this.color2uniform.set(color2InHSL[0], color2InHSL[1], color2InHSL[2]);
      },
      onComplete: () => {
        this.color2Value.set([targetColor2[0], targetColor2[1], targetColor2[2]]);
        this.tw2 = null;
      }
    });

    // Tween third point color
    const color3InHSL =  [this.color3Value[0], this.color3Value[1], this.color3Value[2]];
    const targetColor3 = this.greenGradientColor3;

    if(this.tw3) this.tw3.kill();
    this.tw3 = gsap.to(color3InHSL, {
      duration: duration,
      0: targetColor3[0],
      1: targetColor3[1],
      2: targetColor3[2],
      ease: "power2.inOut",
      onUpdate: () => {
        this.color3uniform.set(color3InHSL[0], color3InHSL[1], color3InHSL[2]);
      },
      onComplete: () => {
        this.color3Value.set([targetColor3[0], targetColor3[1], targetColor3[2]]);
        this.tw3 = null;
      }
    });

    // Tween second point color
    const color4InHSL =  [this.color4Value[0], this.color4Value[1], this.color4Value[2]];
    const targetColor4 = this.greenGradientColor4;

    if(this.tw4) this.tw4.kill();
    this.tw4 = gsap.to(color4InHSL, {
      duration: duration,
      0: targetColor4[0],
      1: targetColor4[1],
      2: targetColor4[2],
      ease: "power2.inOut",
      onUpdate: () => {
        this.color4uniform.set(color4InHSL[0], color4InHSL[1], color4InHSL[2]);
      },
      onComplete: () => {
        this.color4Value.set([targetColor4[0], targetColor4[1], targetColor4[2]]);
        this.tw4 = null;
      }
    });
    
  }

  public switchToBlueGreen(duration = 1): void {
    
    // Tween first point color
    const color1InHSL = [this.color1Value[0], this.color1Value[1], this.color1Value[2]]
    const targetColor1 = this.blueGreenGradientColor1;

    if(this.tw) this.tw.kill();
    this.tw = gsap.to(color1InHSL,
      {
      duration: duration,
      0: targetColor1[0],
      1: targetColor1[1],
      2: targetColor1[2],
      ease: "power2.inOut",
      onUpdate: () => {
        this.color1uniform.set(color1InHSL[0], color1InHSL[1], color1InHSL[2]);
      },
      onComplete: () => {
        this.color1Value.set([targetColor1[0], targetColor1[1], targetColor1[2]]);
        this.tw = null;
      }
    });

    // Tween second point color
    const color2InHSL = [this.color2Value[0], this.color2Value[1], this.color2Value[2]];
    const targetColor2 = this.blueGreenGradientColor2;

    if(this.tw2) this.tw2.kill();
    this.tw2 = gsap.to(color2InHSL, {
      duration: duration,
      0: targetColor2[0],
      1: targetColor2[1],
      2: targetColor2[2],
      ease: "power2.inOut",
      onUpdate: () => {
        this.color2uniform.set(color2InHSL[0], color2InHSL[1], color2InHSL[2]);
      },
      onComplete: () => {
        this.color2Value.set([targetColor2[0], targetColor2[1], targetColor2[2]]);
        this.tw2 = null;
      }
    });

    // Tween third point color
    const color3InHSL =  [this.color3Value[0], this.color3Value[1], this.color3Value[2]];
    const targetColor3 = this.blueGreenGradientColor3;

    if(this.tw3) this.tw3.kill();
    this.tw3 = gsap.to(color3InHSL, {
      duration: duration,
      0: targetColor3[0],
      1: targetColor3[1],
      2: targetColor3[2],
      ease: "power2.inOut",
      onUpdate: () => {
        this.color3uniform.set(color3InHSL[0], color3InHSL[1], color3InHSL[2]);
      },
      onComplete: () => {
        this.color3Value.set([targetColor3[0], targetColor3[1], targetColor3[2]]);
        this.tw3 = null;
      }
    });

    // Tween second point color
    const color4InHSL =  [this.color4Value[0], this.color4Value[1], this.color4Value[2]];
    const targetColor4 = this.blueGreenGradientColor4;

    if(this.tw4) this.tw4.kill();
    this.tw4 = gsap.to(color4InHSL, {
      duration: duration,
      0: targetColor4[0],
      1: targetColor4[1],
      2: targetColor4[2],
      ease: "power2.inOut",
      onUpdate: () => {
        this.color4uniform.set(color4InHSL[0], color4InHSL[1], color4InHSL[2]);
      },
      onComplete: () => {
        this.color4Value.set([targetColor4[0], targetColor4[1], targetColor4[2]]);
        this.tw4 = null;
      }
    });
    
  }
    
  protected _genCode(slots: ChunksSlots): void {
    /**
     * add code in various places in the initial passes (see screen_fx.frag)
     */
    slots.add('pf'   , FragCode({slot:'pf'   }))
    slots.add('postf'    , FragCode({slot:'postf'    }))
  }
}