import Player, { CHECKXPLATFORM, CHECKY } from "./Player";
import Time from "@webgl/Time";
import { Expo, gsap } from "gsap";
import { quat, vec3 } from "gl-matrix";
import { RenderContext } from "@webgl/core/Renderer";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";
import { PlatformRenderable, PlatformType } from "@/services/models/PlatformModel";
import PlatformReflection from "./PlatformReflection";
import { PlatformReflectionRenderable, PlatformReflectionType } from "@/services/models/PlatformReflectionModel";

export default class Platform {
  position: vec3;
  rotation: quat;
  renderable: MeshRenderer;
  platformMovingSpeed = 0.002;
  platformMovingSpeedFactor = 1;
  platformXAngle = 0;
  platformX = 0;
  platformXAmplitude = 0.5;
  movingSide = 1;
  _inUse = false
  progress = 0;

  platformReflection: PlatformReflection | null = null;

  tw: gsap.core.Tween;

  y = 0;
  yBump = 0;


  constructor(public platformRenderable: PlatformRenderable, private platformReflectionRenderableMap: Map<PlatformReflectionType, PlatformReflectionRenderable>, public type: PlatformType) {
    this.position = vec3.create();
    this.rotation = quat.create();
    this.renderable = this.platformRenderable.renderer;
    this.movingSide = Math.sign(Math.random() - 0.5);
    this.platformXAngle = 0;
    this.platformX = 0;
    this.platformXAmplitude = 0.25 + Math.random() * 0.25;
    this.platformReflection = new PlatformReflection(this.platformReflectionRenderableMap.get(type.toString() + "_reflect" as PlatformReflectionType), this)
    this.platformMovingSpeed =
      0.005 +
      Math.random() * 0.005 +
      Math.random() * 0.1 * (this.progress / 100);
  }

  checkCollision(avPosY = 0, avPosX = 0) {
    return avPosY > Math.round(this.position[1] * 100) / 100 - CHECKY &&
      avPosY < Math.round(this.position[1] * 100) / 100 + CHECKY &&
      avPosX >
      Math.round(this.position[0] * 100) / 100 - CHECKXPLATFORM &&
      avPosX <
      Math.round(this.position[0] * 100) / 100 + CHECKXPLATFORM
  }

  setInUse(b: boolean) {
    this._inUse = b;
  }

  getInUse() {
    return this._inUse;
  }

  setProgress(progress: number) {
    this.progress = progress;
  }

  setY(y: number) {
    this.y = this.yBump = y;
  }

  checkBump() {
    if (!this._inUse) return
    if (this.platformRenderable.plateformType === "platformOnelife") {
      // const cam = AppService.glapp.renderer.cameras.camera;
      const o = { y: this.position[1], rot: 0 };
      const def = this.position[1] - 1.5;
      this.tw = gsap.to(o, {
        duration: 1,
        y: def,
        rot: Math.PI * 2,
        ease: Expo.easeIn,
        onUpdate: () => {
          this.position[1] = o.y;
          quat.identity(this.rotation);
          quat.rotateZ(this.rotation, this.rotation, o.rot);
        },
      });

      //this.noRender = true;
    } else this.yBump = this.y - 0.075;
  }

  preRender(): void {
    if (!this._inUse) return
    if (this.platformRenderable.plateformType === "platformSmall") {
      const dt = Time.stableDt * this.platformMovingSpeedFactor;
      this.platformXAngle += this.platformMovingSpeed * dt;
      this.platformX = Math.sin(this.platformXAngle) * this.platformXAmplitude;
      const nx = this.platformX;
      this.position[0] = Player.getModuloX(nx);
    }

    if (this.platformRenderable.plateformType !== "platformOnelife") {
      this.position[1] += (this.yBump - this.position[1]) * 0.2;
      this.yBump += (this.y - this.yBump) * 0.2;
    }

    this.platformReflection?.preRender();
  }

  render(ctx: RenderContext): void {
    if (!this._inUse) return
    // if (this.noRender) return;
    vec3.copy(this.renderable.node.position, this.position);
    quat.copy(this.renderable.node.rotation, this.rotation);
    this.renderable.node.invalidate();
    this.renderable.node.updateWorldMatrix();
    this.renderable.render(
      ctx.gl,
      ctx.camera,
      ctx.mask,
      ctx.pass,
      ctx.glConfig
    );

    this.platformReflection?.render(ctx);
  }

  dispose() {
    this.tw?.kill();
  }
}
