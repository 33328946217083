import Gltf from "nanogl-gltf/lib/Gltf";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";

export type EnemyRenderable = {
  enemyType: EnemyType;
  renderer: MeshRenderer;
};

export type EnemyType = "copyCat";

const enemyRenderablesMap = new Map<EnemyType, EnemyRenderable>();
enemyRenderablesMap.set("copyCat", {
  enemyType: "copyCat",
  renderer: null,
});

export function setEnemyRenderer(gltf: Gltf) {
  enemyRenderablesMap.forEach(
    (enemyRenderable) =>
      (enemyRenderable.renderer = gltf.getNode(
        enemyRenderable.enemyType
      ).renderable)
  );
}

export default enemyRenderablesMap;
