/////////////
/////////////////////////////////
//////////
import AppResources from "@/services/AppResources";
import {
  CreateAppStateInterpreter,
  AppStateInterpreter,
} from "@/services/states/AppStateMachine";
// import GameScene from "@webgl/GameScene";
import GLApp from "@webgl/GLApp";
import GameScene from "@webgl/GameScene";

export class AppServiceClass {
  private static _instance: AppServiceClass;

  glapp: GLApp;
  state: AppStateInterpreter;

  resources: AppResources;

  static getInstance() {
    if (!AppServiceClass._instance) {
      AppServiceClass._instance = new AppServiceClass();
    }
    return AppServiceClass._instance;
  }

  get Scene(): GameScene {
    return this.glapp.renderer.scene;
  }

  constructor() {
    // /// #if DEBUG
    // gui.btn("Save Game", () => this.saveGame());
    // gui.btn("Clear Save", () => this.clearSave());
    // /// #endif
    
    this.state = CreateAppStateInterpreter();

    if(process.env.VUE_APP_COMING_SOON === 'true') return;
    this.resources = new AppResources();
    this.glapp = new GLApp();
  }

  start() {
    this.state.start();
    this.state.send({ type: "INIT" });

    if(process.env.VUE_APP_COMING_SOON === 'true') return;
    this.glapp.start();
  }
}

const AppService = AppServiceClass.getInstance();

export default AppService;
