var fn = function( obj ){
var __t,__p='';
__p+='#if __VERSION__ == 300\n    #define IN in\n    #define OUT out\n#else\n    #define IN attribute\n    #define OUT varying\n#endif\n\nOUT float vAlpha;\nOUT float vColor;\n\nvoid main(void){\n  gl_FragColor.rgb = vec3(1., 1., 1.);\n  gl_FragColor.a = .4 + (vAlpha) * 0.5;\n//   gl_FragColor.a = 1.;\n}\n';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
