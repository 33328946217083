var fn = function( obj ){
var __t,__p='';
__p+='#pragma SLOT version\n\n#pragma SLOT definitions\n\n\n#ifndef hasNormals\n  #define hasNormals 1\n#endif \n#ifndef hasTangents\n  #define hasTangents hasNormals\n#endif \n\n#if hasTangents && !hasNormals \n  #pragma error tan but no nrm\n  error\n#endif\n\n\n#if !hasTangents && __VERSION__ != 300\n  #extension GL_OES_standard_derivatives : enable\n#endif \n\n#pragma SLOT precision\n\n'+
( require( "nanogl-pbr/glsl/includes/glsl-compat.frag" )() )+
'\n\n#pragma SLOT pf\n\n\nuniform vec3 uCameraPosition;\nIN vec3 vWorldPosition;\n\n#if hasNormals\nIN mediump vec3 vWorldNormal;\n#endif \n\n#if HAS_normal && hasTangents\nIN mediump vec3 vWorldTangent;\nIN mediump vec3 vWorldBitangent;\n#endif \n\n\n'+
( require( "nanogl-pbr/glsl/includes/math.glsl" )() )+
'\n'+
( require( "nanogl-pbr/glsl/includes/color.glsl" )() )+
'\n'+
( require( "nanogl-pbr/glsl/includes/normals.glsl" )() )+
'\n\nvec3 rotate( mat4 m, vec3 v )\n{\n  return m[0].xyz*v.x + m[1].xyz*v.y + m[2].xyz*v.z;\n}\n\n\nuniform mat4 uViewMatrix;\nuniform sampler2D tMatcap;\n\n//                MAIN\n// ===================\n\nvoid main( void ){\n\n  #pragma SLOT f\n\n  #if alphaMode( MASK )\n    if( alpha() < alphaCutoff() ) discard;\n  #endif\n\n\n  #if alphaMode( MASK )\n    FragColor.a = 1.0;\n  #elif alphaMode( BLEND )\n    FragColor.a = alpha();\n  #else\n    FragColor.a = 1.0;\n  #endif\n\n  vec3 worldNrm   = normalize(COMPUTE_NORMAL());\n  vec3 viewNormal = normalize( rotate( uViewMatrix, worldNrm) );\n  vec3 viewDir    = normalize( uCameraPosition - vWorldPosition );\n  float grazing   = 1.0-sdot( worldNrm, -viewDir );\n  vec2 matcapCoord = (viewNormal.xy * grazing) * vec2(0.5, -0.5) + 0.5;\n  FragColor.rgb = texture2D( tMatcap, matcapCoord ).rgb;\n\n  #pragma SLOT postf\n\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
