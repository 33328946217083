import Gltf from "nanogl-gltf/lib/Gltf";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";

export type GoodieRenderable = {
  goodieType: GoodieType;
  renderer: MeshRenderer;
};

export type GoodieType = "goodieWaterDroplet" | "goodieMirror" | "goodieSkinTint";

const goodiesRenderablesMap = new Map<GoodieType, GoodieRenderable>();
goodiesRenderablesMap.set("goodieWaterDroplet", {
  goodieType: "goodieWaterDroplet",
  renderer: null,
});
goodiesRenderablesMap.set("goodieMirror", {
  goodieType: "goodieMirror",
  renderer: null,
});
goodiesRenderablesMap.set("goodieSkinTint", {
  goodieType: "goodieSkinTint",
  renderer: null,
});

export function setGoodieRenderer(gltf: Gltf) {
  goodiesRenderablesMap.forEach(
    (goodieRenderable) =>
      (goodieRenderable.renderer = gltf.getNode(
        goodieRenderable.goodieType
      ).renderable)
  );
}

export default goodiesRenderablesMap;
