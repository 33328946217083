export type Level = {
  level: number;
  maxEnemyNumber: number;
  copyCatFactor: number;
  maxGoodieNumber: number;
  goodieFactor: number;
  goodieWaterDropletFactor: number;
  goodieSkinTintFactor: number;
  goodieMirrorFactor: number;
  platformStaticFactor: number;
  platformMovingFactor: number;
  platformOneLifeFactor: number;
};

export const MAX_DIFFICULTY_LEVEL = 5;

export const LEVELS: Level[] = [
  {
    level: 0,
    maxEnemyNumber: 0,
    copyCatFactor: 0,
    maxGoodieNumber: 3,
    goodieFactor: 0.9,
    goodieWaterDropletFactor: 0.5,
    goodieSkinTintFactor: 0.5,
    goodieMirrorFactor: 0,
    platformStaticFactor: 1,
    platformMovingFactor: 0,
    platformOneLifeFactor: 0,
  },
  {
    level: 1,
    maxEnemyNumber: 1,
    copyCatFactor: 0.0,
    maxGoodieNumber: 3,
    goodieFactor: 0.9,
    goodieWaterDropletFactor: 0.4,
    goodieSkinTintFactor: 0.6,
    goodieMirrorFactor: 0,
    platformStaticFactor: 0.85,
    platformMovingFactor: 0.0,
    platformOneLifeFactor: 0.15,
  },
  {
    level: 2,
    maxEnemyNumber: 2,
    copyCatFactor: 0.3,
    maxGoodieNumber: 3,
    goodieFactor: 0.7,
    goodieWaterDropletFactor: 0.3,
    goodieSkinTintFactor: 0.4,
    goodieMirrorFactor: 0.3,
    platformStaticFactor: 0.6,
    platformMovingFactor: 0.2,
    platformOneLifeFactor: 0.2,
  },
  {
    level: 3,
    maxEnemyNumber: 2,
    copyCatFactor: 0.5,
    maxGoodieNumber: 3,
    goodieFactor: 0.7,
    goodieWaterDropletFactor: 0.3,
    goodieSkinTintFactor: 0.3,
    goodieMirrorFactor: 0.4,
    platformStaticFactor: 0.5,
    platformMovingFactor: 0.2,
    platformOneLifeFactor: 0.3,
  },
  {
    level: 4,
    maxEnemyNumber: 2,
    copyCatFactor: 0.7,
    maxGoodieNumber: 3,
    goodieFactor: 0.5,
    goodieWaterDropletFactor: 0.2,
    goodieSkinTintFactor: 0.2,
    goodieMirrorFactor: 0.6,
    platformStaticFactor: 0.5,
    platformMovingFactor: 0.2,
    platformOneLifeFactor: 0.3,
  },
  {
    level: 5,
    maxEnemyNumber: 3,
    copyCatFactor: 1.0,
    maxGoodieNumber: 3,
    goodieFactor: 0.5,
    goodieWaterDropletFactor: 0.1,
    goodieSkinTintFactor: 0.1,
    goodieMirrorFactor: 0.6,
    platformStaticFactor: 0.5,
    platformMovingFactor: 0.2,
    platformOneLifeFactor: 0.3,
  },
  {
    level: 6,
    maxEnemyNumber: 4,
    copyCatFactor: 1.0,
    maxGoodieNumber: 1,
    goodieFactor: 0.5,
    goodieWaterDropletFactor: 0.6,
    goodieSkinTintFactor: 0.1,
    goodieMirrorFactor: 0.3,
    platformStaticFactor: 0.4,
    platformMovingFactor: 0.3,
    platformOneLifeFactor: 0.3,
  },
];

export const getLevel = (progress: number): Level => {
  const level = Math.min(Math.floor(progress * 0.1), MAX_DIFFICULTY_LEVEL);
  return LEVELS[level];
};
