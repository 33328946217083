import Gltf from "nanogl-gltf/lib/Gltf";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";

export type GridRenderable = {
  gridType: GridType;
  renderer: MeshRenderer;
};

export type GridType =
  | "grid_front"
  | "grid_side_left"
  | "grid_side_right";

const gridRenderablesMap = new Map<GridType, GridRenderable>();
gridRenderablesMap.set("grid_front", {
  gridType: "grid_front",
  renderer: null,
});
gridRenderablesMap.set("grid_side_left", {
  gridType: "grid_side_left",
  renderer: null,
});
gridRenderablesMap.set("grid_side_right", {
  gridType: "grid_side_right",
  renderer: null,
});

export function setGridRenderer(gltf: Gltf) {
  gridRenderablesMap.forEach(
    (gridRenderable) =>
      (gridRenderable.renderer = gltf.getNode(
        gridRenderable.gridType
      ).renderable)
  );
}

export default gridRenderablesMap;
