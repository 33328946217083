import { App } from "vue";

import LineByLine from "./animations/LineByLine.vue";

import SvgIcon from "./blocks/Icon/SvgIcon.vue";
import UIImage from "./blocks/Image/UIImage.vue";
import PrimaryBtn from "./blocks/Button/PrimaryBtn.vue";
import FollowMouse from "./blocks/Button/FollowMouse.vue";
import IconBtn from "./blocks/Button/IconBtn.vue";
import CircularText from "./blocks/Circular/CircularText.vue";
import CircularCursor from "./blocks/Cursor/CircularCursor.vue";

import TutorialAvoidEnemy from "./pages/tutorial/steps/TutorialAvoidEnemy.vue";
import TutorialCollectBonus from "./pages/tutorial/steps/TutorialCollectBonus.vue";
import TutorialHowToPlay from "./pages/tutorial/steps/TutorialHowToPlay.vue";

const register = (app: App<Element>): void => {
  app.component("svg-icon", SvgIcon);
  app.component("ui-image", UIImage);

  app.component("line-by-line", LineByLine);

  app.component("primary-btn", PrimaryBtn);
  app.component("follow-mouse", FollowMouse);
  app.component("icon-btn", IconBtn);
  app.component("circular-text", CircularText);
  app.component("circular-cursor", CircularCursor);

  app.component("TutorialHowToPlay", TutorialHowToPlay);
  app.component("TutorialCollectBonus", TutorialCollectBonus);
  app.component("TutorialAvoidEnemy", TutorialAvoidEnemy);
};

export default { register };