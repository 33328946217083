import Enemy from "./Enemy";
import Time from "@webgl/Time";
import PlatformReflection from "./Platform";
import Camera from "nanogl-camera";
import { RenderContext } from "@webgl/core/Renderer";
import { getLevel } from "@/services/models/DifficultyLevelModel";
import { EnemyRenderable, EnemyType } from "@/services/models/EnemyModel";
import { vec2, vec3 } from "gl-matrix";
import PlatformManager from "./PlatformManager";
import Platform from "./Platform";

export default class EnemyManager {
  enemies: Enemy[];
  progress: number;
  platformManager: PlatformManager;
  timeStampLastEnemy: number;
  timeStampLastSpawnTry: number;
  timeElapsedSinceLastBonus: number;
  timeElapsedSinceLastTry: number;

  constructor(private enemyRenderablesMap: Map<EnemyType, EnemyRenderable>) { }

  start() {
    this.progress = 0;
    this.enemies = [];
    this.timeStampLastEnemy = Time.time;
    this.timeStampLastSpawnTry = Time.time;
  }

  setPlatformManager(platformManager: PlatformManager) {
    this.platformManager = platformManager;
  }

  preRender(cam: Camera) {
    this.enemies.forEach((enemy) => {
      enemy.preRender();

      // REMOVE PAST ENEMIES
      if (cam.y - enemy.position[1] > 1.6) {
        enemy.progress = this.progress;
        this.enemies.shift();
      }
    });

    // ADD NEW ENEMIES
    this.timeElapsedSinceLastBonus = Time.time - this.timeStampLastEnemy;
    this.timeElapsedSinceLastTry = Time.time - this.timeStampLastSpawnTry;

    const difficultyLevelData = getLevel(this.progress);
    const enemyLimit = difficultyLevelData.maxEnemyNumber;
    if (
      this.timeElapsedSinceLastBonus > 5000 &&
      this.enemies.length < enemyLimit &&
      this.timeElapsedSinceLastTry > 2000
    ) {
      this.addEnemy();
    }
  }

  render(ctx: RenderContext): void {
    this.enemies.forEach((enemy) => {
      enemy.render(ctx);
    });
  }

  addEnemy() {
    this.timeStampLastSpawnTry = Time.time;
    const difficultyLevelData = getLevel(this.progress);

    const rand = Math.random();

    // Copycat enemy
    if (rand < difficultyLevelData.copyCatFactor) {
      const lastPlatformPosition =
        this.platformManager.latestPlatform.position;

      let position = vec3.fromValues(
        lastPlatformPosition[0],
        lastPlatformPosition[1] - 0.3,
        lastPlatformPosition[2]
      );

      let isCollinding = true;
      let counter = 0;
      while (isCollinding) {
        isCollinding = false;
        counter++;
        if (counter > 400) {
          return;
        }
        if (position[0] > 0.60 || position[0] < -0.60) {
          isCollinding = true;
          position = vec3.fromValues(
            position[0] + ((Math.random() * 2) - 1) * 0.2,
            position[1] + ((Math.random() * 2) - 1) * 0.2,
            position[2]
          );
        }
        this.platformManager.inUsePlatforms.forEach((platform) => {
          if (platform.platformRenderable.plateformType !== "platformSmall") {
            // 2D distance
            const distance = this.distance(
              vec2.fromValues(platform.position[0], platform.position[1]),
              vec2.fromValues(position[0], position[1])
            );
            //console.log("distance " + counter, distance);
            if (distance < 0.4) {
              //find in wich direction to move
              const direction = vec2.fromValues(
                platform.position[0] - position[0],
                platform.position[1] - position[1]
              );
              vec2.normalize(direction, direction);
              if (direction[0] > 0 && direction[1] > 0) {
                position = vec3.fromValues(
                  position[0] - Math.random() * 0.15,
                  position[1] - Math.random() * 0.15,
                  position[2]
                );
              }
              if (direction[0] > 0 && direction[1] < 0) {
                position = vec3.fromValues(
                  position[0] - Math.random() * 0.15,
                  position[1] + Math.random() * 0.15,
                  position[2]
                );
              }
              if (direction[0] < 0 && direction[1] > 0) {
                position = vec3.fromValues(
                  position[0] + Math.random() * 0.15,
                  position[1] - Math.random() * 0.15,
                  position[2]
                );
              }
              if (direction[0] < 0 && direction[1] < 0) {
                position = vec3.fromValues(
                  position[0] + Math.random() * 0.15,
                  position[1] + Math.random() * 0.15,
                  position[2]
                );
              }

              isCollinding = true;

            }
          }
        });
      }
      this.enemies.push(
        new Enemy(this.enemyRenderablesMap.get("copyCat"), this.progress)
      );
      this.timeStampLastEnemy = Time.time;

      this.enemies[this.enemies.length - 1].position[0] = position[0];
      this.enemies[this.enemies.length - 1].position[1] = position[1];
    }
  }

  distance = (point1: vec2, point2: vec2) => {
    const x = point2[0] - point1[0];
    const y = point2[1] - point1[1];

    return Math.sqrt(x * x + y * y);
  }
}
