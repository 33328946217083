import Gltf from "nanogl-gltf/lib/Gltf";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";

export type PlatformReflectionRenderable = {
  plateformReflectType: PlatformReflectionType;
  renderer: MeshRenderer;
};

export type PlatformReflectionType =
  | "platformStatic_reflect"
  | "platformSmall_reflect"
  | "platformOnelife_reflect";

const platformReflectionRenderablesMap = new Map<PlatformReflectionType, PlatformReflectionRenderable>();
platformReflectionRenderablesMap.set("platformStatic_reflect", {
  plateformReflectType: "platformStatic_reflect",
  renderer: null,
});
platformReflectionRenderablesMap.set("platformSmall_reflect", {
  plateformReflectType: "platformSmall_reflect",
  renderer: null,
});
platformReflectionRenderablesMap.set("platformOnelife_reflect", {
  plateformReflectType: "platformOnelife_reflect",
  renderer: null,
});

export function setPlatformReflectionRenderer(gltf: Gltf) {
  platformReflectionRenderablesMap.forEach(
    (platformRenderable) =>
      (platformRenderable.renderer = gltf.getNode(
        platformRenderable.plateformReflectType
      ).renderable)
  );
}

export default platformReflectionRenderablesMap;
