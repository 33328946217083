import { GlslModule } from "@webgl/glsl/glslModule";
import Program from "nanogl/program";
import { GLContext } from "nanogl/types";

/////////////

///
///////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////
////////////
///
//////////////////////////////////////////////////////////////////////////
/////////////////////////////////
///////////////////////////////////////
/////////////////////////////////////////////
////////////////////////////
///
/////////////////
/////////////////////////////////////////
///////////
 

///
///////////////////////////////////////////////////////////////////////////////////////////
/////////////
///////////////
///////////////
/////////////////
////////////
///
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////
///////////////////////////////
///////////////////////////////
///////////////////////////////////////////////////////////////
///////////
///////////////////
///////////////////
////////////
 


/////////

          export default function CreateShader(glslModule:GlslModule ): GlslModule { return glslModule }
          export function CreateProgram( gl:GLContext, vert:GlslModule, frag:GlslModule, defs?:string, datas?: unknown ): Program { return new Program(gl, vert(datas), frag(datas), defs); }

//////////