var fn = function( obj ){
var __t,__p='';
__p+='';
 if(obj.slot === 'pf' ){ 
__p+='\n// SLOT pf (fragment shader declarations)\n// =====================================\n#define PI 3.1415926538\n\nfloat customSmoothstep (float edge0, float edge1, float x) {\n		float t = clamp((x - edge0) / (edge1 - edge0), 0.0, 1.0);\n		return t * t * (3.0 - 2.0 * t);\n}\n\nvec3 hsl2rgb( in vec3 c )\n{\n    vec3 rgb = clamp( abs(mod(c.x*6.0+vec3(0.0,4.0,2.0),6.0)-3.0)-1.0, 0.0, 1.0 );\n\n    return c.z + c.y * (rgb-0.5)*(1.0-abs(2.0*c.z-1.0));\n}\n\n';
 } else if(obj.slot === 'postf' ){ 
__p+='\n\nfloat time = uTime() * .002;\n\n// Four point gradient\n// using hsl colors in js to easily tween colors\nvec4 color0 = vec4(hsl2rgb(uColor1), 1.0); // EAD292\nvec4 color1 = vec4(hsl2rgb(uColor2), 1.0); // 7EB1A8\nvec4 color2 = vec4(hsl2rgb(uColor3), 1.0); // FDAB89\nvec4 color3 = vec4(hsl2rgb(uColor4), 1.0); // DB0C36\n \n//st for uvs \nvec2 uvP = vTexCoord();\n \n//Make point moves \n//vec2 P0 = vec2(0.31,0.3);\n//vec2 P1 = vec2(0.7,0.32);\n// vec2 P2 = vec2(0.28,0.71);\n// vec2 P3 = vec2(0.72,0.75);\n\nvec2 P0 = uPoint1;\nvec2 P1 = uPoint2;\nvec2 P2 = uPoint3;\nvec2 P3 = uPoint4;\n\nP0 = vec2(uPoint1.x + sin(time) * 0.5, uPoint1.y);\nP1 = vec2(uPoint2.x + sin(time) * 0.5, uPoint2.y);\nP2 = vec2(uPoint3.x + sin(time + PI) * 0.5, uPoint3.y);\nP3 = vec2(uPoint4.x + sin(time + PI) * 0.5, uPoint4.y);\n\nvec2 Q = P0 - P2;\nvec2 R = P1 - P0;\nvec2 S = R + P2 - P3;\nvec2 T = P0 - uvP;\n\n\nfloat u;\nfloat t;\n \n	if(Q.x == 0.0 && S.x == 0.0) {\n			u = -T.x/R.x;\n			t = (T.y + u*R.y) / (Q.y + u*S.y);\n	} else if(Q.y == 0.0 && S.y == 0.0) {\n			u = -T.y/R.y;\n			t = (T.x + u*R.x) / (Q.x + u*S.x);\n	} else {\n			float A = S.x * R.y - R.x * S.y;\n			float B = S.x * T.y - T.x * S.y + Q.x*R.y - R.x*Q.y;\n			float C = Q.x * T.y - T.x * Q.y;\n			// Solve Au^2 + Bu + C = 0\n			if(abs(A) < 0.0001)\n					u = -C/B;\n			else\n			u = (-B+sqrt(B*B-4.0*A*C))/(2.0*A);\n			t = (T.y + u*R.y) / (Q.y + u*S.y);\n	}\n	u = clamp(u,0.0,1.0);\n	t = clamp(t,0.0,1.0);\n\n	// These two lines smooth out t and u to avoid visual \'lines\' at the boundaries.  They can be removed to improve performance at the cost of graphics quality.\n	// t = smoothstep(0.0, 1.0, t);\n	// u = smoothstep(0.0, 1.0, u);\n\n	vec4 colorA = mix(color0,color1,u);\n	vec4 colorB = mix(color2,color3,u);\n	FragColor = mix(colorA, colorB, t);\n\n';
 } 
__p+='\n\n';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
