<template>
  <div class="circular-cursor" :class="{ 'active': !hidden }" ref="root">
    <circular-text icon="bb-small" theme="cursor" :repeat="3">Read more</circular-text>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

defineProps({
  hidden: {
    type: Boolean,
    default: true
  }
})

const root = ref(null)
const setTextProperties = ref(null)
const setTextX = ref(null)
const setTextY = ref(null)

const observer = ref(null)

onMounted(() => {
  observer.value = ScrollTrigger.observe({
    type: 'pointer',
    onMove: onMouseMove
  })

  const quickToVarsText = { duration: 0.3, ease: 'cubic' }

  setTextProperties.value = gsap.quickSetter(root.value, 'css')
  setTextX.value = gsap.quickTo(root.value, 'x', quickToVarsText)
  setTextY.value = gsap.quickTo(root.value, 'y', quickToVarsText)

  // onEnter()
})

onBeforeUnmount(() => {
  onLeave()
  observer.value?.kill()
  
  gsap.killTweensOf(root.value)
})

const onMouseMove = ({ x, y }) => {
  setTextProperties.value({ x, y })
  setTextX.value(x)
  setTextY.value(y)
};

const onEnter = () => {
  gsap.to(root.value, {
    opacity: 1,
    duration: 0.25
  })
}

const onLeave = () => {
  gsap.to(root.value, {
    opacity: 0,
    duration: 0.25
  })
}
</script>

<style lang="stylus" scoped>
.circular-cursor
  position fixed
  inset 0
  pointer-events none
  display flex
  align-items center
  justify-content center
  width rem(16)
  height @width
  margin-top rem(48)
  margin-left rem(48)
  opacity 0
  transition opacity 0.25s ease-out
  z-index 999
  &.active
    opacity 1

  > *
    flex none

  :deep(.icon)
    color var(--green)
</style>