import Enemy from "./Enemy";
import Time from "@webgl/Time";
import gui from "@webgl/dev/gui";
import Delay from "@/core/Delay";
import { mod } from "@webgl/math";
import { quat, vec3 } from "gl-matrix";
import Renderer from "@webgl/Renderer";
import Gltf from "nanogl-gltf/lib/Gltf";
import { Power2, gsap } from "gsap";
import EnemyManager from "./EnemyManager";
import Material from "nanogl-pbr/Material";
import Lighting from "@webgl/engine/Lighting";
import GoodiesManager from "./GoodiesManager";
import AppService from "@/services/AppService";
import RenderPass from "@webgl/core/RenderPass";
import PlatformManager from "./PlatformManager";
import Node from "nanogl-gltf/lib/elements/Node";
import Fresnel from "@webgl/glsl/fresnel/Fresnel";
import AudioManager from "@/core/audio/AudioManager";
import { RenderContext } from "@webgl/core/Renderer";
import Gradient from "@webgl/glsl/gradient/Gradient";
import { StandardPass } from "nanogl-pbr/StandardPass";
import { MetalnessSurface } from "nanogl-pbr/PbrSurface";
import GltfResource from "@webgl/resources/GltfResource";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";
import MaterialOverrideExtension from "nanogl-gltf/lib/extensions/MaterialOverrideExtension";
import { AlphaModes } from "nanogl-pbr/AlphaModeEnum";
import Masks from "@webgl/gl/Masks";
import SpeedSphere from "./SpeedSphere";
import Viewport from "@/store/modules/Viewport";
import MaterialOverrides from "./MaterialOverrides";

const VELSCALE = 1.1;
const VELSCALEBONUS = 1.3;
const NODE_ADD = 1;

export const CHECKXPLATFORM = 0.16;
const CHECKXENNEMY = 0.1;
const CHECKXGOODIE = 0.18;

export const CHECKY = 0.05;
const CHECKYENEMY = 0.05;

const MAX_VEL_X = 0.015;

const V3A1 = vec3.create();
const V3A2 = vec3.create();

const path = "bb/bb.gltf";

const pathDeath = "bb/death-animation.gltf";

export default class Player {
  resource: GltfResource;
  gltf: Gltf;
  gltfDeath: Gltf;
  node: Node;

  platformManager: PlatformManager;
  goodiesManager: GoodiesManager;
  enemyManager: EnemyManager;
  speedSphereRenderable: MeshRenderer;
  materialOverride: MaterialOverrides;
  gradient: Gradient;

  velocityY = 0.0;
  velocityTempY = 0.1;
  prevY = 0;
  velAddY = 0.01 * VELSCALE;

  velAddX = 0;

  velocityX = 0;

  velocityFactor = { x: 1.3, y: 1.0 };

  keyDown = false;

  cx = 0;

  inBumpBonus = false;
  
  isTweenDead = false;
  ennemyResponsibleOfDeath: Enemy;
  
  prevTouchMoveX = 0;
  touchMoveX = 0;
  touchStartX = 0;
  touchStartY = 0;
  touchDown = false;
  tw: gsap.core.Tween;
  twColor: gsap.core.Tween;
  twEmissive: gsap.core.Tween;
  invertBonus = 1;
  
  transition: number;
  
  speedSpheres: SpeedSphere[] = [];
  
  rotateZ = 0;
  rotateZQuantity = 0;
  maxRotateZ = 0.4;
  rotateZSpeed = 1.2;
  rotateBackSpeed = 0.05;
  
  isInGodMode = false;
  isInvincible = false;
  isInMirrorBonus = false;
  mirrorBonusTime = 7;
  mirrorBonusTimeout: number;
  
  skinTintBonusTime = 3;
  isInSkinTintBonus = false;
  
  scoreMultiplier = 1;
  waterDropletBonusTime = 2;
  waterDropletBonusTimeout: number;
  isInWaterDropletBonus = false;

  bbBaseColorFactor: any;
  bbEmissiveColor: any;

  blueBodyColor = vec3.fromValues(0.25, 0.56, 0.72);
  greenBodyColor = vec3.fromValues(0.62, 0.76, 0.28);
  blueEmissiveColor = vec3.fromValues(0.0, 0.49, 0.94);
  greenEmissiveColor = vec3.fromValues(0.37, 0.71, 0.0);

  bodyColorValue = vec3.create();
  emissiveColorValue = vec3.create();

  bbScale = 1;
  bbBonusScale = 1.2;
  fresnelIntensity: any;
  fresnelIntensityValue = 0.0;
  fresnelColor: any;
  fresnelColorValue = vec3.fromValues(0.75, 1.0, 0.45);
  fresnelParams: any;
  fresnelParamsValue = { x: 0.87, y: 4.57, z: 0.25 };
  fresnel: Fresnel;
  fresnelBodyColor: any;
  fresnelBodyColorValue = vec3.fromValues(0.13, 0.87, 1.0);
  isFresnelDisplayed = false;
  fresnelTimeout: number;

  renderer: Renderer

  isIntroSequenceDone = false;

  isDeathAnimation = false;
  deathTime = 0

  static getModuloX(x: number) {
    return mod(x, 1.2) - 0.6;
  }

  constructor() {
    this.bodyColorValue.set(this.greenBodyColor);
    this.emissiveColorValue.set(this.greenEmissiveColor);
    // GUI

/////////////////
//////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////
///////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
//////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////
/////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////
//////
//////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////
//////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////
/////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////
/////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////
////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////
//////////////
  }

  async load(renderer: Renderer, matOverride: MaterialOverrides): Promise<any> {
    const overrides = new MaterialOverrideExtension();

    this.renderer = renderer;

    /* CHARACTER MAT */
    overrides.overridePass("BB_Mat", (ctx, mat) => {
      const pass = mat.getPass(RenderPass.COLOR)
        .pass as StandardPass<MetalnessSurface>;

      this.bbEmissiveColor = pass.emissive.attachUniform();
      this.bbEmissiveColor.set(
        this.emissiveColorValue[0],
        this.emissiveColorValue[1],
        this.emissiveColorValue[2]
      );

      pass.emissiveFactor.attachConstant(0.4);
      pass.normalScale.attachConstant(0.9);
      pass.surface.roughnessFactor.attachConstant(0.95);
      pass.surface.metalnessFactor.attachConstant(0.0);

      vec3.copy(this.bodyColorValue, this.greenBodyColor);
      this.bbBaseColorFactor = pass.surface.baseColorFactor.attachUniform();
      this.bbBaseColorFactor.set(
        this.bodyColorValue[0],
        this.bodyColorValue[1],
        this.bodyColorValue[2]
      );

      return null;
    });


    overrides.overridePass("BBFresnel_Mat", (ctx, mat) => {
      const pass = mat.getPass(RenderPass.COLOR)
        .pass as StandardPass<MetalnessSurface>;

      this.fresnelBodyColor = pass.surface.baseColor.attachUniform();
      this.fresnelBodyColor.set(this.fresnelBodyColorValue[0], this.fresnelBodyColorValue[1], this.fresnelBodyColorValue[2]);

      pass.alphaMode.set(AlphaModes[2]);
      pass.glconfig
        .enableBlend()
        .blendFunc(renderer.gl.SRC_ALPHA, renderer.gl.ONE_MINUS_SRC_ALPHA)
        .enableCullface()
        .enableDepthTest()
        .depthMask(false);
      pass.mask = Masks.BLENDED;

      //Fresnel
      this.fresnel = new Fresnel();
      this.fresnelColor = this.fresnel.color.attachUniform();
      this.fresnelColor.set(this.fresnelColorValue[0], this.fresnelColorValue[1], this.fresnelColorValue[2]);

      this.fresnelParams = this.fresnel.params.attachUniform(
        "uFresnelParams",
        3
      );
      this.fresnelParams.set(this.fresnelParamsValue.x, this.fresnelParamsValue.y, this.fresnelParamsValue.z);

      this.fresnelIntensity = this.fresnel.intensity.attachUniform(
        "uFresnelIntensity",
        1
      );
      this.fresnelIntensity.set(0.0);

      pass.inputs.add(this.fresnel);

      return null;
    });

    this.resource = new GltfResource(path, renderer.gl, {
      defaultTextureFilter: renderer.gl.LINEAR_MIPMAP_LINEAR,
      extensions: [overrides],
    });

    const resourcedeath = new GltfResource(pathDeath, renderer.gl, {
      defaultTextureFilter: renderer.gl.LINEAR_MIPMAP_LINEAR,
      extensions: [],
    });

    this.gltf = await this.resource.load();
    this.gltfDeath = await resourcedeath.load();

    this.node = this.gltf.getNode("BB");
    this.node.add(this.gltf.getNode("BBFresnel"));

    this.materialOverride = matOverride;
  }

  onLoaded(
    platformManager: PlatformManager,
    goodiesManager: GoodiesManager,
    enemyManager: EnemyManager,
    speedSphereRenderable: MeshRenderer,
    gradient: Gradient
  ): void {
    this.platformManager = platformManager;
    this.goodiesManager = goodiesManager;
    this.enemyManager = enemyManager;
    this.speedSphereRenderable = speedSphereRenderable;
    this.gradient = gradient;

    this.gltfDeath.root.setScale(0.25)
    this.gltfDeath.root.invalidate();
    this.gltfDeath.root.updateWorldMatrix()
  }

  setupLighting(lighting: Lighting, renderer: Renderer) {
    lighting.lightSetup.prepare(renderer.gl);
    const materials = [] as Material[];
    for (const renderable of this.gltf.renderables) {
      for (const mat of renderable.materials) {
        if (materials.indexOf(mat) === -1) {
          materials.push(mat);
        }
      }
    }
    for (const material of materials) {
      lighting.setupMaterial(material);
    }
  }

  start() {
    this.velocityY = 0;
    this.velAddY = 0.00 * VELSCALE;
    this.prevTouchMoveX = window.innerWidth / 2;

    this.speedSpheres = [];

    this.velocityX = 0;
    this.node.x = 0;
    this.node.z = 0.3;
    this.cx = 0.6;
    this.node.invalidate();
    this.node.updateWorldMatrix();
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
    if (!Viewport.isDesktop) {
      window.addEventListener("touchstart", this.touchStart);
      window.addEventListener("touchend", this.touchEnd);
    }

    this.isDeathAnimation = false;
    this.tweenScale(1, 0)
    this.gradient.switchToBlue(0);
    this.materialOverride.portal.switchColor(this.materialOverride.portal.blueColor, 0);
    this.switchColor(this.greenBodyColor, this.greenEmissiveColor, 0);

    this.isFresnelDisplayed = false;

    this.ennemyResponsibleOfDeath = null;

    this.isInMirrorBonus = false;
    this.isInWaterDropletBonus = false;
    this.isInSkinTintBonus = false;
    this.scoreMultiplier = 1;
  }

  introSequence() {
    if (this.isIntroSequenceDone) return;

    this.tw = gsap.to(this.node, {
      duration: 1.5,
      delay: 1,
      y: 2.4,
      ease: "Power1.easeOut",
      onComplete: () => {
        AppService.state.send("INTRO_SEQUENCE_DONE");
      },
    });
  }

  changeState = async (state: any) => {
    if (state.matches("game.idle.playing.intro")) {
      this.isIntroSequenceDone = false;
      this.introSequence();
    }
    if (state.matches("game.idle.playing.gameplay")) {
      this.isIntroSequenceDone = true;
    }

    this.isDeathAnimation = state.matches("game.dead_monster")
    if (state.matches("game.dead_monster")) {
      this.deathTime = 0
      AudioManager.instance.playUI("death1", 0.4);
      setTimeout(() => {
        AudioManager.instance.playUI("death2", 0.6);
      } , 600);
    }
  };

  stop() {
    if (this.tw) this.tw.kill();
    if (this.twColor) this.twColor.kill();
    if (this.twEmissive) this.twEmissive.kill();
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("keyup", this.onKeyUp);
    if (!Viewport.isDesktop) {
      window.removeEventListener("touchstart", this.touchStart);
      window.removeEventListener("touchend", this.touchEnd);
    }
    this.velocityY = 0;
    this.velAddY = 0.01 * VELSCALE;

    this.invertBonus = 1;

    this.velAddX = 0;
    this.keyDown = false;
    this.touchDown = false;
    this.velocityX = 0;
    this.touchMoveX = 0;

    this.node.y = this.node.x = 0;
    this.node.z = 0.4;
    quat.identity(this.node.rotation);
    this.rotateZQuantity = 0;
    this.node.invalidate();
    this.node.updateWorldMatrix();

    this.invertBonus = 1;
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (!this.isIntroSequenceDone) return;
    if (e.key === "ArrowLeft") {
      this.keyDown = true;
      this.velAddX = 0.001 * this.invertBonus;
    }
    if (e.key === "ArrowRight") {
      this.keyDown = true;
      this.velAddX = -0.001 * this.invertBonus;
    }
    // if (e.code === "KeyT") {
    //   this.gradient.switchToBlue();
    //   this.materialOverride.portal.switchColor(this.materialOverride.portal.blueColor);
    // }
    // if (e.code === "KeyY") {
    //   this.gradient.switchToGreen();
    //   this.materialOverride.portal.switchColor(this.materialOverride.portal.greenColor);
    // }
    // if (e.code === "KeyU") {
    //   this.gradient.switchToBlueGreen();
    //   this.materialOverride.portal.switchColor(this.materialOverride.portal.blueGreenColor);
    // }
  };

  onKeyUp = () => {
    this.velAddX = 0;
    this.keyDown = false;
  };

  touchStart = (e: TouchEvent) => {
    this.touchDown = true;
    this.keyDown = true;
    const touch = e.touches[0];
    if (touch.clientX < window.innerWidth / 2) {
      this.velAddX = 0.001 * this.invertBonus;
      this.renderer.scene.onTouchActive.emit({ isLeft: true, isRight: false })
    } else {
      this.velAddX = -0.001 * this.invertBonus;
      this.renderer.scene.onTouchActive.emit({ isLeft: false, isRight: true })
    }
  };

  touchEnd = () => {
    this.touchDown = false;
    this.velAddX = 0;
    this.keyDown = false;
    this.renderer.scene.onTouchActive.emit({ isLeft: false, isRight: false })
  };

  onBump() {
    this.velAddY = 0.01 * VELSCALE;
    this.velocityY = 0;
    AudioManager.instance.playUI("bump", 0.5);
  }

  switchShield(status: boolean, duration = 1): void {
    const targetValue = status ? 0.33 : 0;
    gsap.to(this,
      {
      duration: duration,
      fresnelIntensityValue: targetValue,
      ease: "power2.inOut",
      onUpdate: () => {
        this.fresnelIntensity.set(this.fresnelIntensityValue);
      },
      onComplete: () => {
        if(!status) this.isFresnelDisplayed = false;
      }
    });
  }

  flickerShield() {
    const tl = gsap.timeline({repeat: 3});
    tl.to(this,
      {
        duration: 0.25,
        fresnelIntensityValue: 0,
        ease: "Sine.easeInOut",
        onUpdate: () => {
          this.fresnelIntensity.set(this.fresnelIntensityValue);
        },
      })
      .to(this,
        {
          duration: 0.25,
          fresnelIntensityValue: 0.33,
          ease: "Sine.easeInOut",
          onUpdate: () => {
            this.fresnelIntensity.set(this.fresnelIntensityValue);
          }
        })
  }

  async onBumpBonus() {
    this.velAddY = 0.01 * VELSCALEBONUS;
    this.velocityY = 0;
    this.inBumpBonus = true;
    await Delay(1000);

    this.inBumpBonus = false;
  }

  async onWaterDropletBonus() {
    AudioManager.instance.playUI("waterdroplet", 0.4);
    if (this.isInWaterDropletBonus){
      clearTimeout(this.waterDropletBonusTimeout);
      this.waterDropletBonusTimeout = setTimeout(() => {
        this.onWaterDropletBonusEnd();
      }, this.waterDropletBonusTime * 1000);
      return;
    }
    this.isInWaterDropletBonus = true;
    this.scoreMultiplier = 3;
    this.gradient.switchToGreen(0.5);
    this.switchColor(this.blueBodyColor, this.blueEmissiveColor, 0.4);
    this.materialOverride.portal.switchColor(this.materialOverride.portal.greenColor, 0.5);
    this.tweenScale(this.bbBonusScale, 0.4);

    this.waterDropletBonusTimeout = setTimeout(() => {
      this.onWaterDropletBonusEnd();
    }, this.waterDropletBonusTime * 1000);
  }

  onWaterDropletBonusEnd() {
    this.isInWaterDropletBonus = false;
    this.scoreMultiplier = 1;
    this.gradient.switchToBlue();
    this.materialOverride.portal.switchColor(this.materialOverride.portal.blueColor);
    this.switchColor(this.greenBodyColor, this.greenEmissiveColor);
    this.tweenScale(1);
  }

  async onSkinTintBonus() {
    AudioManager.instance.playUI("skintint", 0.4);
    this.inBumpBonus = true;
    this.isInSkinTintBonus = true;
    const startY = this.node.y;
    this.prevY = startY;
    this.velocityTempY = 0;
    this.tw = gsap.to(this.node, {
      duration: this.skinTintBonusTime,
      y: startY + 6,
      ease: "power2.inout",
      onComplete: () => {
        this.node.invalidate()
        this.node.updateWorldMatrix()
        this.velocityY = 0;
        this.velAddY = 0;
        this.inBumpBonus = false;
        this.isInSkinTintBonus = false;
        this.gradient.switchToBlue();
        this.materialOverride.portal.switchColor(this.materialOverride.portal.blueColor);
      }
    });
    this.gradient.switchToBlueGreen(0.5);
    this.materialOverride.portal.switchColor(this.materialOverride.portal.blueGreenColor, 0.5);
    // await Delay(this.skinTintBonusTime * 1000);

  }

  async onMirrorBonus() {
    AudioManager.instance.playUI("mirror", 0.4);
    if (this.isInMirrorBonus) {
      this.isFresnelDisplayed = true;
      clearTimeout(this.mirrorBonusTimeout);
      clearTimeout(this.fresnelTimeout);
      this.mirrorBonusTimeout = setTimeout(() => {
        this.onMirrorBonusEnd();
      }, this.mirrorBonusTime * 1000);
      this.fresnelTimeout = setTimeout(() => {
        this.flickerShield();
      } , this.mirrorBonusTime * 1000 - 2000);

      return;
    }
    this.isInMirrorBonus = true;
    this.isInvincible = true;
    this.isFresnelDisplayed = true;
    this.switchShield(true, 0.4);

    this.mirrorBonusTimeout = setTimeout(() => {
      this.onMirrorBonusEnd();
    }, this.mirrorBonusTime * 1000);
    this.fresnelTimeout = setTimeout(() => {
      this.flickerShield();
    } , this.mirrorBonusTime * 1000 - 2000);
  }

  onMirrorBonusEnd() {
    this.isInMirrorBonus = false;
    this.isInvincible = false;
    this.switchShield(false);
  }

  switchColor(targetColor: vec3, targetEmissiveColor: vec3, duration = 1,): void {
    const color = [
      this.bodyColorValue[0],
      this.bodyColorValue[1],
      this.bodyColorValue[2],
    ];

    const emissiveColor = [
      this.emissiveColorValue[0],
      this.emissiveColorValue[1],
      this.emissiveColorValue[2],
    ];
    if (this.twColor) this.twColor.kill();
    this.twColor = gsap.to(color, {
      duration: duration,
      0: targetColor[0],
      1: targetColor[1],
      2: targetColor[2],
      ease: "power2.inOut",
      onUpdate: () => {
        this.bbBaseColorFactor.set(color[0], color[1], color[2]);
      },
      onComplete: () => {
        this.bodyColorValue.set([
          targetColor[0],
          targetColor[1],
          targetColor[2],
        ]);
        this.twColor = null;
      },
    });
    if(this.twEmissive) this.twEmissive.kill();
    this.twEmissive = gsap.to(emissiveColor, {
      duration: duration,
      0: targetEmissiveColor[0],
      1: targetEmissiveColor[1],
      2: targetEmissiveColor[2],
      ease: "power2.inOut",
      onUpdate: () => {
        this.bbEmissiveColor.set(emissiveColor[0], emissiveColor[1], emissiveColor[2]);
      },
      onComplete: () => {
        this.emissiveColorValue.set([
          targetEmissiveColor[0],
          targetEmissiveColor[1],
          targetEmissiveColor[2],
        ]);
        this.twEmissive = null;
      },
    });
  }

  tweenScale(scale: number, duration = 1) {
    gsap.to(this, {
      duration: duration,
      bbScale: scale,
      ease: "power2.inOut",
    });
  }

  spawnSpeedSphere() {
    this.speedSpheres.push(
      new SpeedSphere(
        vec3.fromValues(
          this.node.x,
          this.node.y - 0.05 - Math.random() * 0.05,
          this.node.z
        ),
        this.speedSphereRenderable
      )
    );
  }

  checkSpeedSpheres() {
    const toDelete: number[] = [];

    for (let i = 0; i < this.speedSpheres.length; i++) {
      const sphere = this.speedSpheres[i];
      if (sphere.time === 1) toDelete.push(i);
    }

    for (let i = 0; i < toDelete.length; i++) {
      this.speedSpheres.splice(toDelete[i], 1);
    }
  }

  preRender(renderer: Renderer): void {
    this.checkSpeedSpheres();

    if (this.isTweenDead) return;

    if (this.velocityY > 0.01 && this.isInSkinTintBonus) {
      this.spawnSpeedSphere();
    }

    const dtX = this.velocityFactor.x * Time.stableDt;
    const dtY = this.velocityFactor.y * 1 / Time.stableDt;
    const invDt = 1 / Time.stableDt;

    if (!this.isInSkinTintBonus) {
      this.velocityY += this.velAddY;
      if (this.isIntroSequenceDone) {
        this.velAddY = Math.max(
          -0.0009 * VELSCALE * Time.stableDt,
          this.velAddY - 0.002 * VELSCALE
        );
      }
      if (!this.isDeathAnimation) this.node.y += this.velocityY * NODE_ADD * Time.stableDt;
    } else {
      const v = (Math.abs(this.node.y - this.prevY) / NODE_ADD) * 0.6;
      this.velocityY += (v - this.velocityY) * (0.1 * Time.stableDt);
      this.prevY = this.node.y;
    }

    this.velocityX += this.velAddX * Time.stableDt;
    if (!this.keyDown) this.velocityX += (0 - this.velocityX) * (0.1 * Time.stableDt);
    this.velocityX = Math.min(
      MAX_VEL_X,
      Math.max(-MAX_VEL_X, this.velocityX)
    );
    this.cx += this.velocityX * dtX;
    const nx = this.cx;
    let tscale = Math.max(0, this.velocityY) * 60;
    tscale = Math.min(1.5, tscale);
    this.node.scale[0] = this.bbScale;
    this.node.scale[1] = this.bbScale + tscale * tscale * 0.25;
    this.node.scale[2] = this.bbScale;
    if (!this.isDeathAnimation) this.node.x = Player.getModuloX(nx);

    //Rotate character
    if (
      !this.keyDown &&
      (this.rotateZQuantity > 0.01 || this.rotateZQuantity < -0.01)
    ) {
      if (this.rotateZQuantity > 0.01 && this.rotateZQuantity < -0.01) {
        this.rotateZQuantity = 0;
      }
      this.node.rotateZ(-(0 - this.rotateZQuantity) * this.rotateBackSpeed * Time.stableDt);
      this.rotateZQuantity -=
        -(0 - this.rotateZQuantity) * this.rotateBackSpeed * Time.stableDt;
    } else if (
      this.keyDown &&
      this.rotateZQuantity < this.maxRotateZ &&
      this.rotateZQuantity > -this.maxRotateZ
    ) {
      this.node.rotateZ(-this.velocityX * this.rotateZSpeed * Time.stableDt);
      this.rotateZQuantity += this.velocityX * this.rotateZSpeed * Time.stableDt;
    }

    // Check collisions
    const avPosX = Math.round(this.node.x * 100) / 100;
    const avPosY = Math.round(this.node.y * 100) / 100;

    // Check platforms collision
    if (this.velocityY < 0 && !this.inBumpBonus) {
      this.platformManager.inUsePlatforms.forEach((platform) => {
        if (platform.checkCollision(avPosY, avPosX)) {
          this.onBump();
          platform.checkBump();
        }
      })

      // Check goodies collision
      this.goodiesManager.goodies.forEach((goodie, i) => {
        if (
          avPosY > goodie.position[1] - CHECKY &&
          avPosY < goodie.position[1] + CHECKY &&
          avPosX > goodie.position[0] - CHECKXGOODIE &&
          avPosX < goodie.position[0] + CHECKXGOODIE &&
          !goodie.noRender
        ) {
          if(this.isDeathAnimation) return;
          if (goodie.goodieRenderable.goodieType === "goodieWaterDroplet") {
            this.onWaterDropletBonus();
          } else if (goodie.goodieRenderable.goodieType === "goodieSkinTint") {
            this.onSkinTintBonus();
          } else if (goodie.goodieRenderable.goodieType === "goodieMirror") {
            this.onMirrorBonus();
          }
          goodie.checkBump();
        }
      });

      //Sin on fresnel
      if(this.isFresnelDisplayed){
        const fresnelIntensityModifier = this.fresnelIntensityValue + Math.fround(Math.sin(Time.time * 0.005) * 0.05);
        this.fresnelIntensity.set(fresnelIntensityModifier);
      }
      
      //Sin on fresnel mesh
      //this.node._children[0].setScale(1 + Math.fround((Math.sin(Time.time * 0.005) + 1) * 0.5 * 0.1));
    }

    // Check enemies collision
    this.enemyManager.enemies.forEach((enemy) => {
      if (
        avPosY > enemy.position[1] - CHECKYENEMY &&
        avPosY < enemy.position[1] + CHECKYENEMY &&
        avPosX > enemy.position[0] - CHECKXENNEMY &&
        avPosX < enemy.position[0] + CHECKXENNEMY &&
        !enemy.noRender
      ) {
        if (this.isInvincible || this.isInGodMode || this.isInSkinTintBonus) {
          enemy.noRender = true;
        } else {
          AppService.state.send("GAME_MONSTER_DEAD");
          
        }
      }
    });

    // Check if player is dead
    const playerScreenPos = this.getScreenPos(this.node, renderer);
    if (playerScreenPos[1] > renderer.glview.canvasHeight) {
      if (this.isInSkinTintBonus) return;
      if (this.isInvincible || this.isInGodMode) {
        this.onBumpBonus();
      } else {
        AppService.state.send("GAME_DEAD");
      }
    }

    if (this.isDeathAnimation) {
      const timeAnim = (this.deathTime * 0.001)
      this.deathTime += Time.scaledDt
      for (const animation of this.gltfDeath.animations) {
        animation.evaluate(timeAnim);
      }

      vec3.copy(this.gltfDeath.root.position, this.node._wposition)
      this.gltfDeath.root.invalidate()
      this.gltfDeath.root.updateWorldMatrix()
    }
  }

  render(ctx: RenderContext): void {
    for (let i = 0; i < this.speedSpheres.length; i++) {
      this.speedSpheres[i].render(ctx);
    }
    if (this.isDeathAnimation) {
      for (const renderable of this.gltfDeath.renderables) {
        renderable.render(ctx.gl, ctx.camera, ctx.mask, ctx.pass, ctx.glConfig);
      }

    } else {
      for (const renderable of this.gltf.renderables) {
        if(renderable.node.name === "BBFresnel" && !this.isFresnelDisplayed)continue;
        renderable.render(ctx.gl, ctx.camera, ctx.mask, ctx.pass, ctx.glConfig);
      }
    }
  }

  getScreenPos(node: Node, renderer: Renderer, wp: vec3 = null): number[] {
    if (wp) {
      vec3.copy(V3A1, wp);
    } else {
      node.updateWorldMatrix();
      vec3.set(V3A1, node._wmatrix[12], node._wmatrix[13], node._wmatrix[14]);
    }
    vec3.transformMat4(V3A2, V3A1, renderer.camera._viewProj);

    const x = ((V3A2[0] + 1) / 2) * renderer.glview.canvasWidth;
    const y = ((-1 * V3A2[1] + 1) / 2) * renderer.glview.canvasHeight;

    return [x, y];
  }
}
