import { vec3, mat4 } from "gl-matrix";
import Texture2D from "nanogl/texture-2d";
import SpotLight from "nanogl-pbr/lighting/SpotLight";
import { RenderContext } from "@webgl/core/Renderer";
import Frustum, { FrustumRenderOptions } from "./FrustumGuizmo";
import Guizmo from "./CrossGuizmo";
import TextureDraw from "./TextureDraw";
import TextRenderer from "./Text";
import Grid, { GridOrientation } from "./Grid";
import Lines from "./Lines";
import ConeGuizmo from "./ConeGuizmo";
import { GLContext } from "nanogl/types";
import gui from "../gui";
import { CreateGui, Gui, GuiFolder, RangeGui } from "../gui/decorators";
import { Control } from "../gui/api";
import Points from "./Points";
import GLState from "nanogl-state/GLState";

const Orange = 0xfe9f2c;

/////////////

///////////////////
//////////
/////////////////
////////////////
//

//////////////////////
///////////////
/////////////////
//////////////////
//

///////////////////
///

///////////////////////////////////
//////////////////////////
//////////////////////////////
///////////
 

///////////////////////
/////////////////////
/////////////////////////////
///////////////////
////////////////////////////////////
///////////////////////

/////////////////
///////////////////
///////////////////
///////////////////////
/////////////////////////////
/////////////
///////////////
/////////////////

//////
/////////////////

//////
/////////////////

//////
/////////////////

//////
/////////////////

///////////////////////////////////
//////////////////////////

////////////////////////////////////
///////////////////////////////////////////
//////////////////////////////////////////

//////////////////////////////////////
////////////////////////
///////////////////////
////////////////////////
/////////////////////

/////////////////////////////////
///////////////////////////////////
///////////////////////////////////
///////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////
///////////////////////////////
/////////////////////////////////

//////////////////////////

////////////////////
///

///////////
/////////////////////////////
//////////////////////////////
///////////////////////////
//////////////////////////////
//////////////////////////////
///

////////////////////////////////////////////////////////////
//////////////////////////////

/////////////////////////////////////////////
/////////////////////////////////////////////
///////////////////////////////////////
///////
////////////////////////////////////
////////////////////////////
/////

//////////////////////////////////////////////
///

//////////////////////
//////////////////////////////
//////////////////////////////
/////////////////////////
//////////////////////////////////////////////
////////////
////////////////////////////////////
/////
///

//////////////////////
/////////////////////////////////////////////////
//////////////////////////////
///////////////////////////////////////////////
///

////////////////////////////////////////////////////
//////////////////////////////
///////////////////////////////////////////
///

///////////////////////////////////////////
//////////////////////////////
/////////////////////////////////////
///

//////////////////////////////////////////////
//////////////////////////////
////////////////////////////////////
///

//////////////////////
//////////////////////////////
////////////////////////////
///

/////////////////////////////////////
///////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
///

//////////////////////////////
//////////////////////////////

///////////////////
//////////////////////////////////////////////
////////////////////////////////////////////////
///////////////////////////////////////////////
/////////
//////

////////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////
/////

/////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
/////

//////////////////////////////////////////////////
////////////////////////////////////////////
///////////////////////////////////////////////
/////////////////////////////////////////////

////////////////////////////////////
///////////////////////////////////
/////

///////////////////////////////////////
/////////////////////////////////////////////////
//////////////////////////
///////////
///////////////////
/////////////////
/////////////////
/////////////////////////////////////////////////
//////////////////////////////////////////////////
////////////
/////////////
//////////
///////
/////

//////////////////////////////////
///////////////////////////////////
////////////////////////////////

////////////////////////////////
///

////////////////////////////
///////////////////////////////////
//////////////////////////////
//////////////////////////
/////////////////////////////////////////////////////////////////
///
 

/////////////////////////////

////////////////////////////////////
////////////////////////////////////
 

///////////////////
///////

//////////////////////////
/////////////////////////////
////
///////////////////////////
//////////////////////////
////

//////////////////////////////
//////////////////////////////////
////

///////////////////////////////
///////////////////////////////
////

////////////////////////////////////
////////////////////////////
////

///////////////////////////////
//////////////////////////////
////

//////////////////////////////////////////////////////////
/////////////////////////////////////////
////

/////////////////////////////////////
///////////////////////////////
////

////////////////////////////////////////////////////////////////
//////////////////////////////////////////
////

////////////////////////////////////////////////////
////////////////////////////////////
////

////////////////////////////
///////////////////////////
////

///////////////////////////////////////////
//////////////////////////////////
////

////////////////////////////////////
//////////////////////////
//////////////////////
////
//

/////////////////////////

////

/////////

const DebugDraw = {
  enabled:false,
  init(gl:GLContext):void{0},
  drawGuizmo(x : vec3 | mat4 ):void{0},
  drawFrustum( vp : mat4 ):void{0},
  drawCone( m : mat4 ):void{0},
  drawSpotLight( l :SpotLight ):void{0},
  drawTexture( name:string, t:Texture2D, flipY = false ):void{0},
  drawText( txt:string, wpos: vec3 ):void{0},
  drawLine( a:vec3, b:vec3, color = Orange ):void{0},
  drawPoint( a:vec3 ):void{0},
  render(ctx:IRenderContext):void{0}
}

export default DebugDraw;

//////////
//*/
