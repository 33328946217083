<template>
  <div class="background" ref="canvas" />
</template>

<script setup>
import { ref, onMounted, onUnmounted, reactive, computed, nextTick } from 'vue';
import { Renderer, Program, Texture, Mesh, Vec2, Flowmap, Triangle } from 'ogl';
import Viewport from '@/store/modules/Viewport';

const canvas = ref(null);
const renderer = ref(null);
const gl = ref(null);
const aspect = ref(1);
const mouse = reactive(new Vec2(-1));
const velocity = reactive(new Vec2());
const _rafID = ref(null);
let lastTime = ref(null);
let lastMouse = ref(new Vec2());

const props = defineProps({
  theme: {
    type: String,
    default: 'default',
    required: true
  }
})

onMounted(async() => {
  init()  
  createFlowmap()
});

const resize = () => {
  renderer.value.setSize(window.innerWidth, window.innerHeight);
  aspect.value = window.innerWidth / window.innerHeight;
};

const isTouchCapable = 'ontouchstart' in window;
const updateMouse = (e) => {
  if (e.changedTouches && e.changedTouches.length) {
    e.x = e.changedTouches[0].pageX;
    e.y = e.changedTouches[0].pageY;
  }
  if (e.x === undefined) {
    e.x = e.pageX;
    e.y = e.pageY;
  }

  mouse.x = e.x / gl.value.renderer.width;
  mouse.y = 1.0 - e.y / gl.value.renderer.height;

  if (!lastTime.value) {
    lastTime.value = performance.now();
    lastMouse.value.set(e.x, e.y);
  }

  const deltaX = e.x - lastMouse.value.x;
  const deltaY = e.y - lastMouse.value.y;

  lastMouse.value.set(e.x, e.y);

  let time = performance.now();
  let delta = Math.max(14, time - lastTime.value);
  lastTime.value = time;

  velocity.x = deltaX / delta;
  velocity.y = deltaY / delta;

  velocity.needsUpdate = true;
};

const init = () => {
  renderer.value = new Renderer({ dpr: 2 });
  gl.value = renderer.value.gl;
  canvas.value.appendChild(gl.value.canvas);
  
  window.addEventListener('resize', resize, false);
  resize();
}

const createFlowmap = () => {
  const flowmap = new Flowmap(gl.value, {
    size: 64,
    falloff: 0.4,
    alpha: 0.5,
    dissipation: 0.98
  });
  const geometry = new Triangle(gl.value);

  const texture = new Texture(gl.value);
  const img = new Image();
  img.onload = () => (texture.image = img);
  img.src = props.theme === 'default' ? require('@/assets/images/background/default.jpg') : require('@/assets/images/advices/advices-bg.jpg');

  const vertex = /* glsl */ `
    attribute vec2 uv;
    attribute vec2 position;

    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = vec4(position, 0, 1);
    }
  `;

  const fragment = /* glsl */ `
    precision highp float;

    uniform sampler2D tWater;
    uniform sampler2D tFlow;
    uniform float uTime;

    varying vec2 vUv;

    void main() {
      // R and G values are velocity in the x and y direction
      // B value is the velocity length
      vec3 flow = texture2D(tFlow, vUv).rgb;

      // Use flow to adjust the uv lookup of a texture
      vec2 uv = gl_FragCoord.xy / ${Viewport.windowHeight * 2}.;
      uv += flow.xy * 0.25;
      vec3 tex = texture2D(tWater, uv).rgb;

      gl_FragColor.rgb = tex;
      gl_FragColor.a = 1.0;
    }
  `;

  const program = new Program(gl.value, {
    vertex,
    fragment,
    uniforms: {
      uTime: { value: 0 },
      tWater: { value: texture },
      tFlow: flowmap.uniform,
    },
  });

  const mesh = new Mesh(gl.value, { geometry, program });

  if (isTouchCapable) {
    window.addEventListener('touchstart', updateMouse, false);
    window.addEventListener('touchmove', updateMouse, false);
  } else {
    window.addEventListener('mousemove', updateMouse, false);
  }

  const update = (t) => {
    _rafID.value = requestAnimationFrame(update);

    if (!velocity.needsUpdate) {
      mouse.x = -1;
      mouse.y = -1;
      velocity.x = 0;
      velocity.y = 0;
    }
    velocity.needsUpdate = false;

    flowmap.aspect = aspect.value;
    flowmap.mouse.copy(mouse);

    flowmap.velocity.lerp(velocity, velocity.len() ? 0.5 : 0.1);

    flowmap.update();

    program.uniforms.uTime.value = t * 0.001;

    renderer.value.render({ scene: mesh });
  };

  requestAnimationFrame(update);
}

onUnmounted(() => {
  window.removeEventListener('resize', resize, false);

  if (isTouchCapable) {
    window.removeEventListener('touchstart', updateMouse, false);
    window.removeEventListener('touchmove', updateMouse, false);
  } else {
    window.removeEventListener('mousemove', updateMouse, false);
  }

  if (_rafID.value) {
    cancelAnimationFrame(_rafID.value);
  }
});
</script>

<style lang="stylus" scoped>
.background, canvas 
  position fixed
  inset 0
  full()
  z-index 1
</style>
