<template>
  <div class="noise-background">
    <div class="noise" />
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="stylus" scoped>
.noise-background
  position fixed
  inset 0
  full()
  z-index 99
  pointer-events none

  .noise
    position fixed
    background-image url('@/assets/images/noise.png')
    animation: grain 6s steps(10) infinite;
    background-repeat: repeat;
    content: '';
    height: 300%;
    left: -100%;
    opacity: 0.9;
    pointer-events: none;
    top: -100%;
    width: 300%;
    will-change: transform;

@keyframes grain {
  0% { transform: translate3d(20%, -15%, 0) }
  10% { transform: translate3d(-20%, -15%, 0) }
  20% { transform: translate3d(20%, -5%, 0) }
  30% { transform: translate3d(-20%, -5%, 0) }
  40% { transform: translate3d(20%, 5%, 0) }
  50% { transform: translate3d(-20%, 5%, 0) }
  60% { transform: translate3d(20%, 15%, 0) }
  70% { transform: translate3d(-20%, 15%, 0) }
  80% { transform: translate3d(20%, 5%, 0) }
  90% { transform: translate3d(-20%, 5%, 0) }
  100% { transform: translate3d(20%, -5%, 0) }
}
</style>
