interface ScoreCanvasParams {
  score: string
}

interface TextOptions {
  score: string
  scoreSize: number
}

interface Sizes {
  width: number
  height: number
}

export default class ScoreCanvas {
  private _canvas: HTMLCanvasElement;
  private _ctx: CanvasRenderingContext2D;
  private _image: HTMLImageElement
  private _textOption: TextOptions
  private _sizes: Sizes
  public finalImage: string
  public promise: Promise<string>
  private _resolve: CallableFunction
  private _font: string;
  private _qualityRetina = 2;

  constructor(options: ScoreCanvasParams) {
    this.dispose();

    this._textOption = {
      score: options.score,
      scoreSize: 86.25 * this._qualityRetina,
    }

    this._font = "CentraNo2-Light";
    
    this._canvas = document.createElement("canvas")
    this._ctx = this._canvas.getContext("2d", { alpha: false })
    this._image = new Image()
    this._sizes = {
      width: 0,
      height: 0
    }

    this.promise = new Promise((resolve) => {
      this._resolve = resolve
    })

    this.setFont()
  }

  formatScore(score : number) : string {
    let finalScore : string = score.toString()
    let stringLength : number = finalScore.split('').length
    
    for (let i = 0; i < 4 - stringLength; i++) {
      finalScore = '0' + finalScore
    }
    
    return finalScore
  }

  async setFont() {
    const font = new FontFace(this._font, "url('/fonts/CentraNo2-Light.ttf')", { weight: "300" });
    await font.load();
    
    document.fonts.add(font);
    this.createImage();
  }

  wrapText(text: string, x: number, y: number, lineHeight: number) {
    const maxWidth = 300 * this._qualityRetina;
    let words: string[] = text.split(' ');
    let line = '';

    for(let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' ';
      const metrics = this._ctx.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > maxWidth && n > 0) {
        this._ctx.fillText(line, x, y);
        line = words[n] + ' ';
        y += lineHeight;
      } else {
        line = testLine;
      }
    }

    this._ctx.fillText(line, x, y);
  }

  createImage() {
    this._image.src = require(`@/assets/images/share/share.jpg`)
    this._image.onload = () => {
      this._sizes = {
        width: this._image.width * this._qualityRetina,
        height: this._image.height * this._qualityRetina
      }

      this.setSize()
      this.draw()
    }
  }

  setSize() {
    this._canvas.width = this._sizes.width
    this._canvas.height = this._sizes.height
  }

  draw() {
    this._ctx.drawImage(this._image, 0, 0, this._sizes.width, this._sizes.height);
    
    // Global style
    this._ctx.fillStyle = 'white'
    this._ctx.textAlign = 'center'

    // Score
    this._ctx.font = `200 ${this._textOption.scoreSize}px ${this._font}`
    this._ctx.fillText(this._textOption.score, this._sizes.width / 2, 375 * this._qualityRetina)

    this.finalImage = this._canvas.toDataURL("image/jpg", 1.0)

    this._resolve(this.finalImage)
  }

  dispose() {
    this._canvas = null
    this._ctx = null
    this._image = null
    this._textOption = null
    this._sizes = null
    this.finalImage = null
    this.promise = null
    this._resolve = null
  }

}