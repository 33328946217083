import { createMachine, interpret } from "xstate";
import { AppEvent } from "./AppEvents";
import { ActivityId } from "@webgl/activities/ActivityRegistry";
import AppService from "../AppService";
import { StepIds } from '../models/TutorialModel';
import UrlParams from "@/core/UrlParams";

function CreateContext() {
  return {
    loaded: false,
    score: 0,
    step: 0 as number,
  };
}

export type AppStateContext = ReturnType<typeof CreateContext>;

export type AppStateType = {
  value: string;
  context: AppStateContext;
};

const idsActivitiesStart: ActivityId[] = ["game1"];

// =====
// UTILS
// =====
function createActivities(id: ActivityId[]) {
  return function () {
    id.forEach((id) => AppService.Scene.activities.createActivity(id));
  };
}

function loadActivity(id: ActivityId) {
  return function (): Promise<unknown> {
    return AppService.Scene.activities.loadActivity(id);
  };
}

function startActivity(id: ActivityId) {
  return function () {
    AppService.Scene.activities.startActivity(id);
  };
}

function stopActivity(id: ActivityId) {
  return function () {
    AppService.Scene.activities.stopActivity(id);
  };
}

let INIT_TARGET = "initializing";

if (process.env.VUE_APP_COMING_SOON === 'true' || UrlParams.getBool("enable-coming-soon")) {
  INIT_TARGET = "coming_soon";
}

// SKIP INTRO
/////////////
////
///////////////////////////////////////////
//////////////////////////////////////////
/////////////////////////////////////
///
///////////////////////////////////////////////////
 
//////////

function CreateAppStateMachine() {
  return createMachine<AppStateContext, AppEvent, AppStateType>(
    {
      predictableActionArguments: true,

      id: "app",

      initial: "initial",

      context: CreateContext(),

      states: {
        // STATE INIT
        initial: {
          on: {
            INIT: {
              target: INIT_TARGET,
            },
          },
        },

        initializing: {
          initial: "load_game_resources",
          states: {
            load_game_resources: {
              entry: "createActivites",

              invoke: [
                {
                  id: "load-activites-start",
                  src: "loadActivitesStart",
                  onDone: {
                    actions: (ctx) => (ctx.loaded = true),
                    target: "#app.landing_page",
                  },
                },
              ],
            },
          },
        },

        coming_soon: {},

        landing_page: {
          on: {
            LAUNCH_TUTORIAL: {
              target: "#app.tutorial",
            },
            GAME_LAUNCH: {
              target: "#app.game",
            },
            GO_TO_ADVICES: {
              target: "#app.advices",
            },
          },
        },

        advices: {
          on: {
            BACK_TO_MENU: {
              target: "#app.landing_page",
            },
          },
        },

        tutorial: {
          initial: "idle",
          states: {
            idle: {
              always: [
                { target: "running", actions: (ctx) => (ctx.step = 0) },
                // { target: "running", cond: 'isSkipped', actions: (ctx) => (ctx.step = 0) },
              ]
            },
            running: {
              on: {
                NEXT_STEP: {
                  cond: 'hasNext',
                  actions: 'next'
                },
                PREV_STEP: {
                  cond: 'hasPrev',
                  actions: 'prev'
                },
                GO_TO_STEP: {
                  actions: (ctx, evt) => (ctx.step = evt.step)
                },
                GAME_LAUNCH: {
                  target: "#app.game",
                },
                BACK_TO_MENU: {
                  target: "#app.landing_page",
                },
              },
            }
          },
        },

        game: {
          initial: "idle",
          exit: [stopActivity("game1")],
          states: {
            idle: {
              entry: [startActivity("game1")],
              initial: "playing",
              on: {
                PAUSE_GAME: {
                  target: ".paused",
                },
                RESUME_GAME: {
                  target: ".playing.gameplay",
                },
              },
              states: {
                paused : {
                  on: {
                    LAUNCH_TUTORIAL: {
                      target: "#app.tutorial",
                    },
                    BACK_TO_MENU: {
                      target: "#app.landing_page",
                    },
                    RESTART_GAME: {
                      target: "#app.game.restart",
                    },
                    GO_TO_ADVICES: {
                      target: "#app.advices",
                    },
                  },
                },
                playing: {
                  initial: "intro",
                  states: {
                    intro : {
                      // after: {
                      //   2000: { target: 'gameplay' }
                      // },
                      on: {
                        INTRO_SEQUENCE_DONE: {
                          target: "gameplay",
                        },
                      },
                    },
                    gameplay: {
                    }
                  },
                  on: {
                    GAME_DEAD: {
                      target: "#app.game.dead_monster",
                    },
                    GAME_MONSTER_DEAD: {
                      target: "#app.game.dead_monster",
                    },
                    GAME_VOID_DEAD: {
                      target: "#app.game.dead_monster",
                    },
                    UPDATE_SCORE: {
                      actions: (ctx, evt) => (ctx.score = evt.score),
                    },
                  },
                }
              }
            },
            dead_monster: {
              after: {
                2000: { target: 'dead' }
              }
            },
            // dead_void: {
            //   on: {
            //     GAME_DEAD: {
            //       target: "dead",
            //     },
            //   },
            // },
            restart : {
              entry: [stopActivity("game1")],
              after: {
                50: { target: '#app.game.idle' }
              }
            },
            dead: {
              entry: [stopActivity("game1")],
              on: {
                RESTART: {
                  target: "#app.game.idle",
                },
                SCOREBOARD_SHOW: {
                  target: "scoreboard",
                },
                GO_TO_ADVICES: {
                  target: "#app.advices",
                },
              },
            },
            scoreboard: {
              on: {
                LEADERBOARD_SHOW: {
                  target: "leaderboard",
                },
                BACK_TO_DEAD: {
                  target: "dead",
                }
              }
            },
            leaderboard: {
              on: {
                RESTART: {
                  target: "#app.game.idle",
                },
                BACK_TO_DEAD: {
                  target: "dead",
                },
                BACK_TO_MENU: {
                  target: "#app.landing_page",
                },
              },
            },
          },
        },
      },
    },

    {
      actions: {
        createActivites() {
          createActivities(idsActivitiesStart);
        },
        next: (ctx) => {
          if (ctx.step < StepIds.length - 1) {
            ctx.step ++
          }
        },
        prev: (ctx) => {
          if (ctx.step > 0) {
            ctx.step --
          }
        }
      },

      services: {
        async loadActivitesStart() {
          const toLoad = idsActivitiesStart.map((id) => loadActivity(id)());
          toLoad.push(AppService.Scene.load());
          await Promise.all(toLoad);
        },
      },

      guards: {
        isSkipped: () => {
          return process.env.VUE_APP_SKIP_TUTORIAL && process.env.VUE_APP_SKIP_TUTORIAL === 'true'
        },
        hasNext: (ctx) => {
          return ctx.step < StepIds.length - 1
        },
        hasPrev: (ctx) => {
          return ctx.step > 0
        }
      },
    }
  );
}

export function CreateAppStateInterpreter() {
  const res = interpret(CreateAppStateMachine());

  res.onTransition((state, evt) => {
    let currentState = state.value;

    if (typeof currentState === "object") {
      const keys = Object.keys(state.value);
      if (keys.length > 0) {
        currentState = keys[0];
      }
    }

    document.body.className = currentState.toString();
  });


///////////////
///////////////////////////////////////
/////////////////////////////////////////////////////////////
///////////////////////////////////////
/////////////////////////////////////////////
////////////////////////////////////////
//////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////
/////////////////////////////////////////////////////////////
////////
////////////

  return res;
}

export type AppStateInterpreter = ReturnType<typeof CreateAppStateInterpreter>;
