import gsap from "gsap";
import gui from "@webgl/dev/gui";
import Chunk from "nanogl-pbr/Chunk";
import FragModule from './Portal.frag'
import TexCoord from "nanogl-pbr/TexCoord";
import vec3 from "gl-matrix/src/gl-matrix/vec3";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import CreateShader from "@webgl/core/CreateProgram";
import Input, { Sampler, ShaderType, Uniform } from "nanogl-pbr/Input";
import Texture2D from "nanogl/texture-2d";

/**
 * this shader can be edited live and hot reloaded in place
 */
const FragCode = CreateShader(FragModule)

export default class Portal extends Chunk {
  colorFactor: Input;
  color1Value: vec3 = vec3.create();
  color1uniform: Uniform;

  decals: Input;
  sampler: Sampler;

  blueColor = vec3.fromValues(0.051, 0.188, 0.78);
  greenColor = vec3.fromValues(0.603, 0.811, 0.294);
  blueGreenColor = vec3.fromValues(0.270, 0.823, 0.721);

  setTexture(tex: Texture2D) {
    this.sampler.set(tex);
  }

  constructor() {
    super(true, false);

    this.addChild((this.colorFactor = new Input("uColorFactor1", 3, ShaderType.FRAGMENT)));
    this.addChild((this.decals = new Input("Decal", 3, ShaderType.FRAGMENT)));
    this.sampler = this.decals.attachSampler(
      "tDecalMask",
      TexCoord.create("aTexCoord0")
    );

    //Set colors values and uniforms
    this.color1Value.set(this.blueColor);

    this.color1uniform = this.colorFactor.attachUniform("uColorFactor1", 3);

    this.color1uniform.set(
      this.color1Value[0],
      this.color1Value[1],
      this.color1Value[2]
    );

    /// #endif

    /**
     * invalidate this chunk's code when live reloading the glsl module
     * `onHmr` noop in non DEBUG mode
     */
    FragCode.onHmr(() => this.invalidateCode());
  }

  switchColor(targetColor: vec3, duration = 1) {
    const color = [this.color1Value[0], this.color1Value[1], this.color1Value[2]]
    gsap.to(color, {
      duration: duration,
      0: targetColor[0],
      1: targetColor[1],
      2: targetColor[2],
      onUpdate: () => {
        this.color1uniform.set(
          color[0],
          color[1],
          color[2]
        );
      },
      onComplete: () => {
        this.color1Value.set(targetColor);
      }
    });
  }

  protected _genCode(slots: ChunksSlots): void {
    /**
     * add code in various places in the initial passes (see screen_fx.frag)
     */
    slots.add("postf", FragCode({ slot: "postf" }));
  }
}