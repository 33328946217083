import { quat, vec3 } from "gl-matrix";
import { RenderContext } from "@webgl/core/Renderer";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";
import { PlatformReflectionRenderable } from "@/services/models/PlatformReflectionModel";
import Platform from "./Platform";

export default class PlatformReflection {
  position: vec3;
  rotation: quat;
  renderable: MeshRenderer;
  linkedPlatform: Platform;

  noRender = false;

  y = 0;
  yBump = 0;


  constructor(public platformRenderable: PlatformReflectionRenderable, linkedPlatform: Platform) {
    this.position = vec3.create();
    this.rotation = quat.create();
    this.renderable = this.platformRenderable.renderer;
    this.noRender = false;
    this.linkedPlatform = linkedPlatform;
  }

  setY(y: number) {
    this.y = this.yBump = y;
  }

  preRender(): void {
    vec3.copy(this.position, this.linkedPlatform.position);
    quat.copy(this.rotation, this.linkedPlatform.rotation);
  }

  render(ctx: RenderContext): void {
    // if (this.noRender) return;
    vec3.copy(this.renderable.node.position, this.position);
    quat.copy(this.renderable.node.rotation, this.rotation);
    this.renderable.node.invalidate();
    this.renderable.node.updateWorldMatrix();
    this.renderable.render(
      ctx.gl,
      ctx.camera,
      ctx.mask,
      ctx.pass,
      ctx.glConfig
    );
  }

  dispose() {
  }
}
