import { vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import { RenderContext } from "@webgl/core/Renderer";
import Grid from "./Grid";
import { GridRenderable, GridType } from "@/services/models/GridModel";

export default class GridManager {
  grids: Grid[];

  progress: number;

  y = 0;
  yLeft = 0;
  yRight = 0;
  gridYOffset = 1.17;
  gridXOffset = 0.62;
  constructor(
    private gridRenderablesMap: Map<GridType, GridRenderable>
  ) {}

  start() {
    this.grids = [];
    this.progress = 0;
    this.y = 0;
    this.yLeft = 0;
    this.yRight = 0;

    this.addFrontGrid();
    this.addLeftGrid();
    this.addRightGrid();

    //this.y += this.gridOffset;

    for (let i = 0; i < 2; i++) {
      this.addFrontGrid();
      this.addLeftGrid();
      this.addRightGrid();
    }
  }

  stop() {
    this.grids.forEach((grid) => grid.dispose());
  }

  addFrontGrid() {
      this.grids.push(
        new Grid(this.gridRenderablesMap.get("grid_front"))
      );

      this.grids[this.grids.length - 1].position = vec3.fromValues(
        0.0,
        this.y,
        0.1
      );
      this.y += this.gridYOffset;
  }

  addLeftGrid() {
    this.grids.push(
      new Grid(this.gridRenderablesMap.get("grid_side_left"))
    );

    this.grids[this.grids.length - 1].position = vec3.fromValues(
      this.gridXOffset,
      this.yRight,
      0.0
    );
    this.grids[this.grids.length - 1].gridRenderable.renderer.node.scale.set([1, 1, 0.3]);
    this.yRight += this.gridYOffset;
  }

  addRightGrid() {
    this.grids.push(
      new Grid(this.gridRenderablesMap.get("grid_side_right"))
    );

    this.grids[this.grids.length - 1].position = vec3.fromValues(
      -this.gridXOffset,
      this.yLeft,
      0.0
    );
    this.grids[this.grids.length - 1].gridRenderable.renderer.node.scale.set([1, 1, 0.3]);
    this.yLeft += this.gridYOffset;
  }


  preRender(cam: Camera): void {
    let deltaUp = Math.abs(this.y - cam.y);

    while (deltaUp < 2.0) {
      this.addFrontGrid();
      this.addLeftGrid();
      this.addRightGrid();
      deltaUp = Math.abs(this.y - cam.y);
    }
    const toDelete: number[] = [];
    this.grids.forEach((grid, i) => {
      grid.preRender();
      if (cam.y - grid.position[1] > 4.0) {
        toDelete.push(i);
      }
    });

    toDelete.forEach((i) => {
      this.grids[i].dispose();
      this.grids.splice(i, 1);
    });
  }

  render(ctx: RenderContext): void {
    this.grids.forEach((grid) => {
      grid.render(ctx);
    });
  }
}
