<template>
  <div class="rotate-device bg-gradient">
    <ui-image :image="{
      url: require('@/assets/images/mobile/rotate.png'),
      double: require('@/assets/images/mobile/rotate@2x.png')
    }" />
    <p>Please rotate your phone</p>
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="stylus" scoped>
.rotate-device
  position fixed
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap rem(24)
  inset 0
  full()
  z-index 9999
</style>