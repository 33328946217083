import { quat, vec3 } from "gl-matrix";
import { RenderContext } from "@webgl/core/Renderer";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";
import { GridRenderable } from "@/services/models/GridModel";

export default class Grid {
  position: vec3;
  rotation: quat;
  scale: vec3;
  renderable: MeshRenderer;

  noRender = false;

  constructor(public gridRenderable: GridRenderable) {
    this.position = vec3.create();
    this.rotation = quat.create();
    this.scale = vec3.create();
    this.renderable = this.gridRenderable.renderer;
    this.noRender = false;

  }

  preRender(): void {

  }

  render(ctx: RenderContext): void {
    // if (this.noRender) return;
    vec3.copy(this.renderable.node.position, this.position);
    quat.copy(this.renderable.node.rotation, this.rotation);
    this.renderable.node.invalidate();
    this.renderable.node.updateWorldMatrix();
    this.renderable.render(
      ctx.gl,
      ctx.camera,
      ctx.mask,
      ctx.pass,
      ctx.glConfig
    );
  }

  dispose() {

  }
}
