import Renderer from "@webgl/Renderer";
import Game1 from "./Game1/Game1";

export const ActivityRegistry = {
  // CMN
  game1: Game1,
} as const;

export type ActivityId = keyof typeof ActivityRegistry;

export const ActivityFactory = {
  create(id: ActivityId, renderer: Renderer) {
    return new ActivityRegistry[id](renderer);
    // switch (id) {
    //   case "entrance":
    //   case "room_3":
    //   case "link_main_room__room_2":
    //   case "link_room_2__room_3":
    //     return new ActivityRegistry[id](renderer, id);
    //   default:
    //     return new ActivityRegistry[id](renderer);
    // }
  },
};
