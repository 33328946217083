import { AudioAmbientInfos } from "@/services/models/AudioAmbientModel";
import { Howl } from "howler";
import { ITEM_VOLUME, ITEM_VOLUME_REDUCED } from "./AudioManager";

export default class AmbientSound {
  howl: Howl
  id: string
  constructor(id: string) {
    this.howl = new Howl({ src: AudioAmbientInfos[id].asset, loop: true, volume: ITEM_VOLUME, sprite: AudioAmbientInfos[id].sprite })
    this.id = id;
  }

  play() {
    this.howl.off("fade")
    this.howl.play("intro")
    this.howl.fade(0, ITEM_VOLUME, 1000)
  }

  stop() {
    this.howl.off("end")
    this.howl.fade(this.howl.volume(), 0, 1000).once("fade", () => this.howl.stop())
  }

  reduce() {
    this.howl.fade(this.howl.volume(), ITEM_VOLUME_REDUCED, 1000)
  }
  restore() {
    this.howl.fade(this.howl.volume(), ITEM_VOLUME, 1000)
  }
  pause() {
    this.howl.pause()
  }
  resume() {
    if(this.howl.playing()) return;
    this.howl?.play()
  }

}