interface LayerPush {
  action: string,
  label: string,
  category: string,
  value?: number
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export function trackVPV(name: string) {
  try {
    window.dataLayer.push({
      event: "vpv",
      VPV: name
    });

    console.log("[GTM VPV] " + name);

  } catch (error) {
    console.error(error);
  }
}

export function trackPush(layer: LayerPush) {
  try {
    window.dataLayer.push({
      event: "event",
      eventAction: layer.action,
      eventLabel: layer.label,
      eventValue: layer.value,
      eventCategory: layer.category
    });

    console.log("[GTM PUSH] " + JSON.stringify(layer));

  } catch (error) {
    console.error(error);
  }
}