<template>
  <div class="low-battery bg-gradient">
    <ui-image :image="{
      url: require('@/assets/images/mobile/low-battery.png'),
      double: require('@/assets/images/mobile/low-battery@2x.png')
    }" />
    <p>Please disable <span>Low power mode</span><br /> to access the full experience</p>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="stylus" scoped>
.low-battery
  position fixed
  inset 0
  full()
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap rem(32)
  z-index 9998

  &.remove
    display none
    opacity 0
    width 0
    height 0
    pointer-events none
    z-index -1

  p
    text-align center
  span
    color var(--pink)
</style>