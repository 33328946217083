import { vec3 } from "gl-matrix";
import { Power2, gsap } from "gsap";
import { RenderContext } from "@webgl/core/Renderer";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";

export default class SpeedSphere {
  positions: vec3[];
  scales: number[];
  rand: number[];
  time = 0;
  constructor(private position: vec3, private renderable: MeshRenderer) {
    const nbs = 3;
    this.positions = [];
    this.scales = [];
    this.rand = [];
    for (let i = 0; i < nbs; i++) {
      this.positions.push(
        vec3.fromValues(
          (Math.random() > 0.5 ? 1 : -1) * 0.01 + Math.random() * 0.04,
          0.05,
          -0.5
        )
      );
      this.scales.push(0.05 + Math.random() * 0.1);
      this.rand.push(0.2 + Math.random() * 0.8);
    }

    this.animeOut();
  }

  animeOut() {
    this.time = 0;
    gsap.to(this, {
      time: 1,
      duration: 1 + Math.random() * 1.5,
      ease: Power2.easeIn,
      onUpdate: () => {
        for (let i = 0; i < this.scales.length; i++) {
          this.scales[i] = Math.max(
            0,
            this.scales[i] - (1 - this.time) * this.rand[i] * 0.02
          );
          this.positions[i][1] -= 0.0025 + this.rand[i] * 0.005;
        }
      },
    });
  }

  render(ctx: RenderContext) {
    for (let i = 0; i < this.positions.length; i++) {
      vec3.add(this.renderable.node.position, this.position, this.positions[i]);
      this.renderable.node.setScale(this.scales[i]);
      //this.renderable.node.scale[1] *= 1.1;
      this.renderable.node.invalidate();
      this.renderable.node.updateWorldMatrix();
      this.renderable.render(
        ctx.gl,
        ctx.camera,
        ctx.mask,
        ctx.pass,
        ctx.glConfig
      );
    }
  }
}