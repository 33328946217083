import { reactive } from "vue"

export const User = reactive({
  name: '',
  id: null,
  // id: "reqSbakvlwrSAv2H_Axt-",
  best_score: 0,
  game_duration: 0
})

export const setName = (name: string) => {
  if (name === '') return
  User.name = name
}

export const setBestScore = (score: number) => {
  const bestScore = localStorage.getItem('bb_best_score')

  if (bestScore) {
    const bestScoreNumber = parseInt(bestScore)
    if (score > bestScoreNumber) {
      User.best_score = score
      localStorage.setItem('bb_best_score', score.toString())
    }
  } else {
    User.best_score = score
    localStorage.setItem('bb_best_score', score.toString())
  }
}