import { quat, vec3 } from "gl-matrix";
import { RenderContext } from "@webgl/core/Renderer";
import { EnemyRenderable } from "@/services/models/EnemyModel";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";

export default class Enemy {
  position: vec3;
  rotation: quat;
  renderable: MeshRenderer;
  noRender = false;
  progress = 0;

  enemyMovingSpeed = 0.005;
  enemyXAmplitude = 0.1;
  enemyXAngle = 0;
  enemyX = 0;

  scale: number;
  scaleSin: number;
  scaleAmp: number;

  constructor(public enemyRenderable: EnemyRenderable, progress: number) {
    this.position = vec3.create();
    this.rotation = quat.create();
    //quat.rotateX(this.rotation, this.rotation, Math.PI / 2);
    this.renderable = this.enemyRenderable.renderer;
    this.progress = progress;
    this.enemyX = 0;
    this.enemyXAngle = 0;
    this.enemyXAmplitude = 0.05 + Math.random() * 0.05;
    this.position[2] = -0.1;
    this.enemyMovingSpeed =
      0.005 +
      Math.random() * 0.005 +
      Math.random() * 0.1 * (this.progress / 100);

    this.scale = 1;
    this.scaleSin = Math.random() * Math.PI * 2;
    this.scaleAmp = 0.1 + Math.random() * 0.1;
  }

  preRender(): void {
    this.scaleSin += 0.02;
    this.scale = Math.sin(this.scaleSin) * this.scaleAmp + 1;
  }

  render(ctx: RenderContext): void {
    if (this.noRender) return;
    vec3.copy(this.renderable.node.position, this.position);
    vec3.set(this.renderable.node.scale, this.scale, this.scale, this.scale);
    quat.copy(this.renderable.node.rotation, this.rotation);
    this.renderable.node.invalidate();
    this.renderable.node.updateWorldMatrix();
    this.renderable.render(
      ctx.gl,
      ctx.camera,
      ctx.mask,
      ctx.pass,
      ctx.glConfig
    );
  }
}
