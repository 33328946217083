export default {
  "meta": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beautyblender PLAY"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level up your blend with Beautyblender®"])}
  },
  "header": {
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "shop_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://beautyblender.com/products/beautyblender%C2%AE-play-color-changing-makeup-sponge"])}
  },
  "landing": {
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level up your blend with Beautyblender<sup>®</sup>."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "challenge_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beat My Score"])},
    "best_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Best Score"])},
    "circular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score a coupon"])}
  },
  "tutorial": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorial"])},
    "step0_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to play"])},
    "step0_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the left and right arrows on your keyboard or fingers to move your Beautyblender from platform to platform."])},
    "step1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build Your Basket"])},
    "step1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catch these objects to unlock bonuses or bonus points!"])},
    "step1_obj_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOUNCE"])},
    "step1_obj_1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boost your way up"])},
    "step1_obj_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCORE MULTIPLIER"])},
    "step1_obj_2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double your score"])},
    "step1_obj_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROTECTIVE SHIELD"])},
    "step1_obj_3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fend off copycats"])},
    "step2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DON’T GET DUPED"])},
    "step2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid these copycats to continue to play."])},
    "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it, let's play!"])}
  },
  "footer": {
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "privacy_policy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://beautyblender.com/pages/privacy-policy"])},
    "terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
    "terms_conditions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://beautyblender.com/pages/terms-conditions"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["©2023 Beautyblender"])}
  },
  "coupon_modal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCORE A COUPON"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out the form below to get an exclusive discount."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign me for up other Beautyblender<sup>®</sup> news & discounts."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "title_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YOU’VE GOT MAIL"])},
    "desc_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your inbox for an exclusive coupon.<br /> Enjoy!"])}
  },
  "dead": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congrats! your score is"])},
    "title_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your score"])},
    "share_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "leaderboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem a coupon code"])}
  },
  "pause": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YOU’VE LEVELED UP!"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring all the right moves is no feat for you."])},
    "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the tutorial"])},
    "start_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start again"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume the game"])}
  },
  "content": {
    "card0_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the brand"])},
    "card0_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Beautyblender, we’ve always believed you should get compliments on your skin not your makeup."])},
    "card0_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
    "card1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does The Color Of Your Beauty Blender Make A Difference?"])},
    "card1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet consectetur. Adipiscing massa sit"])},
    "card1_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
    "card1_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://beautyblender.com/blogs/beauty-101"])},
    "card2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 3 Biggest Mistakes You mIght be Making With Your Blender"])},
    "card2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet consectetur. Adipiscing massa sit"])},
    "card2_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])},
    "card2_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://beautyblender.com/blogs/beauty-101/the-3-biggest-mistakes-you-could-be-making-with-your-blender"])},
    "card3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How I Built This - Beautyblender"])},
    "card3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet consectetur. Adipiscing massa sit"])},
    "card3_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listen Here"])},
    "card3_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://wondery.com/shows/how-i-built-this/episode/10386-beautyblender-rea-ann-silva/"])},
    "card4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How To Clean Your Beautyblender?"])},
    "card4_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet consectetur. Adipiscing massa sit"])},
    "card4_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
    "card4_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://beautyblender.com/blogs/beauty-101/how-to-clean-makeup-sponges"])},
    "card5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Your Perfect Skin Tint Shade"])},
    "card5_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet consectetur. Adipiscing massa sit"])},
    "card5_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
    "card5_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://beautyblender.com/blogs/beauty-101/skin-tint-shade-finder"])},
    "read_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read the article"])}
  },
  "scoreboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover your rank"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See how you stack up among other players, fill in the first 3 letters of your name."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See my rank"])},
    "ban_word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This name is forbidden, please use another one"])}
  },
  "leaderboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLENDERBOARD"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See where you stack up among other players."])}
  },
  "loading": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading the experience"])},
    "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wet Beautyblender<sup>®</sup> till it expands."])},
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Squeeze out excess water."])},
    "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bounce your fave formula for a skin-like application."])}
  },
  "share": {
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COPY THE GAME LINK"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beautyblenderplay.com"])}
  },
  "about": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the brand"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're Changing the Beauty Game"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As your beauty accomplice, Beautyblender® is here to make sure you get compliments on your skin, not your makeup.<br /><br/>\nI created the original pink Beautyblender to easily blend out your fave formulas for a gorgeous, undetectable finish. Dupes will come and go, but two decades later the iconic Beautyblender remains unrivaled in its performance.<br /><br/>\nDesigned to be makeup’s best friend, Beautyblender empowers you to unleash your inner beauty and confidence… all while giving you the best blend ever.<br /><br />\nFrom tools and complexion—expert level results, for pros, first timers and every face in between. That’s been our story since the start in 2002.<br /><br />\nXx Rea Ann Silva<br /><br />\nFounder + CEO"])}
  }
}