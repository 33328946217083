import Delay from "@/core/Delay";
import Signal from "@/core/Signal";
import { ActivityId } from "@webgl/activities/ActivityRegistry";
import { RenderContext } from "@webgl/core/Renderer";
import RenderMask from "@webgl/core/RenderMask";
import { IScene } from "@webgl/engine/IScene";
import Renderer from "@webgl/Renderer";
import SceneActivityManager from "@webgl/SceneActivityManager";

export default class GameScene implements IScene {
  activities: SceneActivityManager;

  onTouchActive: Signal<{ isLeft: boolean, isRight: boolean }> = new Signal<{ isLeft: boolean, isRight: boolean }>();

  constructor(public renderer: Renderer) {
    this.activities = new SceneActivityManager(this.renderer);
  }

  load(): Promise<void> {
    return Promise.resolve();
  }

  unload(): void {}

  async warmup() {
    const activities = [this.activities.getActivity("game1")];

    for (let i = 0; i < activities.length; i++) {
      activities[i].start();
      activities[i].preRender();
      activities[i].rttPass();
    }

    const camera = this.renderer.camera;
    const viewport = this.renderer.viewport;
    camera.updateViewProjectionMatrix(viewport.width, viewport.height);

    const gl = this.renderer.gl;
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    viewport.setupGl(gl);
    gl.clearColor(0.0, 0.0, 0.0, 0.0);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    this.renderer.context.withCamera(this.renderer.camera);

    for (let i = 0; i < activities.length; i++) {
      activities[i].render(this.renderer.context.withMask(RenderMask.OPAQUE));
      activities[i].render(this.renderer.context.withMask(RenderMask.BLENDED));
    }

    for (let i = 0; i < activities.length; i++) {
      activities[i].stop();
    }

    await Delay(200);
  }

  preRender(): void {
    for (const activity of this.activities.active) {
      activity.preRender();
    }
  }

  rttPass(): void {
    for (const activity of this.activities.active) {
      activity.rttPass();
    }
  }

  render(context: RenderContext): void {
    for (const activity of this.activities.active) {
      activity.render(context);
    }
  }
}
