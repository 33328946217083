import AppService from "@/services/AppService";
import { Howl, Howler } from "howler";
import UISprite from "./ui.json";

import {
  AudioRecordId,
  AudioRecordIds,
  AudioRecordInfos,
} from "@/services/models/AudioRecordModel";

import Signal from "../Signal";
import {
  AudioAmbientId,
  AudioAmbientIds,
  AudioAmbientInfos,
} from "@/services/models/AudioAmbientModel";
import AmbientSound from "./AmbientSound";

const AudioEventIds = [
  "play",
  "end",
  "pause",
  "stop",
  "mute",
  "volume",
  "rate",
  "seek",
  "fade",
  "unlock",
];

type AudioEventId = typeof AudioEventIds[number];
export interface RegisteredAudioEvents {
  [event: AudioEventId]: CallableFunction;
}

const Ambients = {
  entrance: "ambient-main",
  main_ambient: "ambient-main",
} as Record<string, string>;

// const AUDIO_SPRITES = {  }

export const ITEM_VOLUME = 0.3;
export const ITEM_VOLUME_REDUCED = 0.1;

export class AudioLib {
  _audio: Map<AudioRecordId, Howl> = new Map<AudioRecordId, Howl>();
  _audioEvents: Map<AudioRecordId, RegisteredAudioEvents> = new Map<
    AudioRecordId,
    RegisteredAudioEvents
  >();
  canPlay = false;
  canPlayEvent = new Signal();
  musicNormalEvent = new Signal();
  _ambientSounds: Map<AudioRecordId, AmbientSound> = new Map<
    AudioRecordId,
    AmbientSound
  >();

  currentAmbient: AmbientSound;

  static _instance: AudioLib;
  static get instance() {
    if (AudioLib._instance == null) {
      AudioLib._instance = new AudioLib();
    }
    return AudioLib._instance;
  }

  configuratorCategoryId: number;

  isMuted = false;

  constructor() {
    Howler.autoSuspend = false;
    Howler.html5PoolSize = 0;
    Howler.usingWebAudio = true;

    AudioRecordIds.forEach((el) => {
      const audioId = el as AudioRecordId;
      this._audio.set(
        audioId,
        new Howl({
          src: AudioRecordInfos[audioId].asset,
          loop: false,
          volume: ITEM_VOLUME,
        })
      );
      this._audioEvents.set(audioId, {});
    });
    AudioAmbientIds.forEach((el) => {
      const audioId = el as AudioAmbientId;
      const ambientSound = new AmbientSound(audioId);
      this._ambientSounds.set(audioId, ambientSound);
    });

    this._audio.set("ui", new Howl(UISprite as any));

    Howler.volume(0);
    Howler.stereo(0);
    this.setCanPlay();

    AppService.state.subscribe(this.onAppState);

    this.configuratorCategoryId = 1;
  }

  onAppState = (e: any) => {
    //console.log(e);
    if (e.event.type === "GAME_LAUNCH" || e.event.type === "RESTART") {
      this.playAmbient("main_ambient");
      if(this.isMuted) {
        this.setMute(true);
      }
    }
    if (e.event.type === "GAME_DEAD" || e.event.type === "GAME_MONSTER_DEAD") {
      this.currentAmbient.stop();
    }
  };

  get muted() {
    return Howler.volume() === 0;
  }

  setCanPlay() {
    this.canPlay = true;
    this.canPlayEvent.emit({});
    Howler.volume(1);
  }

  setMusicNormalVolume() {
    this.musicNormalEvent.emit({});
  }

  toggleMute() {
    if(this.isMuted) {
      this.isMuted = false;
      Howler.volume(1);
    } else {
      this.isMuted = true;
      Howler.volume(0);
    }
  }

  setMute(flag: boolean) {
    Howler.volume(flag ? 0 : 1);
  }

  setVolume(id: AudioRecordId, soundId: number, volume: number) {
    if (!this.canPlay) return;
    this._audio.get(id).volume(volume, soundId);
  }

  fadeVolume(
    id: AudioRecordId,
    soundId: number,
    volume: number,
    duration = 1000
  ) {
    if (!this.canPlay) return;
    const howl = this._audio.get(id);
    howl.fade(
      howl.volume(undefined, soundId) as any,
      volume,
      duration,
      soundId
    );
  }

  play(
    id: AudioRecordId,
    volume = 1.0,
    events: Array<RegisteredAudioEvents>
  ): number {
    if (!this.canPlay) return;

    const howl = this._audio.get(id);
    if (howl.playing()) return;
    const soundId = howl.play(id);
    howl.volume(volume, soundId);

    if (events && events.length) {
      const registeredEvents = this._audioEvents.get(id);
      events.forEach((evt) => {
        const eventName = Object.keys(evt)[0] as AudioEventId;
        registeredEvents[eventName] = evt[eventName];
        howl.on(eventName, () => evt[eventName]());
      });
    }

    this.reduceAmbient();
    howl.on("end", () => this.restoreAmbient());

    return howl.play();
  }

  playUI(name: string, volume = 1.0) {
    if (!this.canPlay) return;

    const howl = this._audio.get("ui");
    //if (howl.playing()) return;

    const soundId = howl.play(name);
    howl.volume(volume, soundId);
  }

  playAmbient(ambientName: string) {
    if (!Ambients[ambientName]) return;
    const id = Ambients[ambientName];

    if (this.currentAmbient && id !== this.currentAmbient.id) {
      this.currentAmbient.stop();
    }

    //if (this.currentAmbient && id === this.currentAmbient.id) return;

    this.currentAmbient = this._ambientSounds.get(id);
    this.currentAmbient.play();
  }

  getDuration(id: AudioRecordId) {
    const howl = this._audio.get(id);
    return howl.duration();
  }

  pause(id: AudioRecordId) {
    if (!this.canPlay) return;

    const howl = this._audio.get(id);
    howl.pause();

    this.restoreAmbient();
  }

  stop(id: AudioRecordId) {
    if (!this.canPlay) return;

    const howl = this._audio.get(id);
    howl.stop();

    this.restoreAmbient();
  }

  playSingle(id: AudioRecordId, seek = 0): number {
    const howl = this._audio.get(id);
    if (howl.playing()) return;
    howl.seek(seek);
    return howl.play();
  }

  stopSingle(id: AudioRecordId) {
    this._audio.get(id).stop();
  }

  reduceAmbient() {
    this.currentAmbient?.reduce();
  }
  pauseAmbient() {
    this.currentAmbient?.pause();
  }
  resumeAmbient() {
    if (!this.canPlay) return;
    this.currentAmbient?.resume();
  }
  restoreAmbient() {
    this.currentAmbient?.restore();
  }
}

export default AudioLib;
